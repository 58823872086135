import { gql } from '@apollo/client';

export const ME = gql`
  query Me {
    me {
      _id
      username
      emails {
        address
        verified
      }
      roles
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($input: UserInput) {
    signUp(input: $input) {
      _id
      emails {
        address
        verified
      }
      createdAt
    }
  }
`;

export const SIGN_IN = gql`
  mutation AdminSignIn($input: UserInput) {
    adminSignIn(input: $input) {
      _id
      username
      emails {
        address
        verified
      }
      roles
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VerifyUser($token: String) {
    verifyUser(token: $token) {
      _id
      username
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation AdminForgotPassword($email: String) {
    adminForgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation AdminResetPassword($token: String, $password: String) {
    adminResetPassword(token: $token, password: $password)
  }
`;

export const VALIDATE_USERNAME = gql`
  mutation ValidateUsername($username: String) {
    validateUsername(username: $username)
  }
`;
