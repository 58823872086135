import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  CardHeader,
  List,
  ListItem,
  ListSubheader,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { IError, IStatDaily } from 'face-id-types';
import { Refresh as RefreshIcon } from '@mui/icons-material';

import { t } from '../../lib';
import { Page } from '../../layout';
import { Loading } from '../../components';

interface Props {
  loading: boolean;
  errors?: IError[];
  data?: IStatDaily[];
  onRefetch?: () => void;
}

const Dashboard = ({ loading, errors, data, onRefetch }: Props) => {
  if (loading) {
    return <Loading />;
  }

  const statToday = data?.[0];
  const statTotal = data?.[1];

  return (
    <Page
      layout="FULL_WIDTH"
      title={t('Dashboard')}
      pageTitle={<Typography variant="h4">{t('Dashboard')}</Typography>}
      headerRight={
        <IconButton onClick={onRefetch}>
          <RefreshIcon />
        </IconButton>
      }
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <Card>
            <CardHeader title={t('DashboardRealTime')} />
            <CardContent>
              <List subheader={<ListSubheader>{t('User')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.user?.signUp}</Typography>
                  }
                >
                  <Typography variant="body1">{t('SignUp')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.user?.signIn}</Typography>
                  }
                >
                  <Typography variant="body1">{t('SignIn')}</Typography>
                </ListItem>
              </List>
              <List subheader={<ListSubheader>{t('Animal')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.animal?.count}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Register')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.animal?.faceId}</Typography>
                  }
                >
                  <Typography variant="body1">{t('FaceId')}</Typography>
                </ListItem>
              </List>

              <List subheader={<ListSubheader>{t('AiData')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.aiData?.user}</Typography>
                  }
                >
                  <Typography variant="body1">{t('User')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.aiData?.animal}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Animal')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statToday?.aiData?.pass}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Pass')}</Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6}>
          <Card>
            <CardHeader title={t('DashboardTotal')} />
            <CardContent>
              <List subheader={<ListSubheader>{t('User')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.user?.signUp}</Typography>
                  }
                >
                  <Typography variant="body1">{t('SignUp')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.user?.signIn}</Typography>
                  }
                >
                  <Typography variant="body1">{t('SignIn')}</Typography>
                </ListItem>
              </List>
              <List subheader={<ListSubheader>{t('Animal')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.animal?.count}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Register')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.animal?.faceId}</Typography>
                  }
                >
                  <Typography variant="body1">{t('FaceId')}</Typography>
                </ListItem>
              </List>

              <List subheader={<ListSubheader>{t('AiData')}</ListSubheader>}>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.aiData?.user}</Typography>
                  }
                >
                  <Typography variant="body1">{t('User')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.aiData?.animal}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Animal')}</Typography>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <Typography>{statTotal?.aiData?.pass}</Typography>
                  }
                >
                  <Typography variant="body1">{t('Pass')}</Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;
