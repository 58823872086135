import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
  IAiVerification,
  IAnimal,
  IError,
  IPagination,
  ISortBy,
} from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IAnimal[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: unknown) => void;
}

interface ActionParams {
  command: string;
  params: unknown;
}

const VerifyList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const actionArgs = useRef<ActionParams>();

  const headers = [
    {
      id: 'user',
      label: t('User'),
      getValue: (obj: IAiVerification) => obj?.user?.username,
    },
    {
      id: 'animal',
      label: t('Animal'),
      getValue: (obj: IAiVerification) => obj?.animal?.name,
    },
    {
      id: 'verification',
      label: t('ImageDetected'),
      getValue: (obj: any) => (
        <img
          src={obj?.data?.[0].verification.s3File.url}
          style={{ height: 80 }}
        />
      ),
    },
    {
      id: 'distance',
      label: t('VerificationDistance'),
      getValue: (obj: any) => `${obj?.data?.[0].verification.distance}`,
    },
    {
      id: 'feedback',
      label: t('Feedback'),
      getValue: (obj: IAiVerification) =>
        `${obj?.feedback?.match ? t('Match') : t('NotMatch')}`,
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IAiVerification) =>
        dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: unknown) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('Animal')}
      pageTitle={
        <Typography variant="h4">
          {t('Animal')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IAnimal) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: (actionArgs.current?.params as unknown[])?.length,
        })}
        onClose={handleConfirm}
      />
    </Page>
  );
};

export default VerifyList;
