import { gql } from '@apollo/client';

export const EVENT_USER_LIST = gql`
  query AdminEventUserList($params: ListParams) {
    adminEventUserList(params: $params) {
      _id
      totalCount
      list {
        _id
        event {
          _id
        }
        user {
          _id
          username
        }
        phoneAuth {
          _id
          name
          phoneNumber
        }
        juminNumber
        bank {
          name
          account
          holder
        }
        address {
          postalCode
          country
          province
          city
          road
          details
          building {
            dong
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const EVENT_USER_VIEW = gql`
  query AdminEventUserView($id: ID) {
    adminEventUserView(id: $id) {
      _id
      event {
        _id
      }
      user {
        _id
        username
      }
      phoneAuth {
        _id
        name
        phoneNumber
      }
      juminNumber
      bank {
        name
        account
        holder
      }
      address {
        postalCode
        country
        province
        city
        road
        details
        building {
          dong
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const EVENT_ANIMAL_LIST = gql`
  query AdminEventAnimalList($params: ListParams) {
    adminEventAnimalList(params: $params) {
      _id
      totalCount
      list {
        _id
        event {
          _id
        }
        user {
          _id
          event {
            _id
          }
          phoneAuth {
            _id
            name
            phoneNumber
          }
        }
        embeddings {
          _id
          data {
            detection {
              version
              s3File {
                bucket
                key
                url
              }
              result
              duration
            }
            embedding {
              version
              s3File {
                bucket
                key
                url
              }
              result
              duration
            }
          }
        }
        animal {
          _id
          name
          breed {
            _id
            name
          }
          gender
          birthday
          neuter
          color
          feature
          adoptionDay
          arn
          owner {
            _id
            username
          }
        }
        arOrg
        arCard {
          _id
          version
          format
        }
        stat {
          upload
          pass
          reject
          days
        }
        inspection {
          status
          rejectMessage
          inspector {
            _id
            username
          }
          inspectedAt
        }
        couponSentAt
        createdAt
      }
    }
  }
`;

export const EVENT_ANIMAL_VIEW = gql`
  query AdminEventAnimalView($id: ID) {
    adminEventAnimalView(id: $id) {
      _id
      event {
        _id
      }
      animal {
        _id
        name
        breed {
          _id
          name
        }
        gender
        birthday
        neuter
        color
        feature
        adoptionDay
        arn
        owner {
          _id
        }
      }
      arOrg
      arCard {
        _id
        version
        format
      }
      inspection {
        status
        rejectMessage
        inspector {
          _id
          username
        }
        inspectedAt
      }
      couponSentAt
      createdAt
    }
  }
`;

export const EVENT_ANIMAL_INSPECT = gql`
  mutation AdminEventAnimalInspect($id: ID, $input: EventAnimalInspectInput) {
    adminEventAnimalInspect(id: $id, input: $input) {
      _id
      event {
        _id
      }
      animal {
        _id
        name
        breed {
          _id
          name
        }
        gender
        birthday
        neuter
        color
        feature
        adoptionDay
        arn
        owner {
          _id
        }
      }
      arOrg
      arCard {
        _id
        version
        format
      }
      inspection {
        status
        rejectMessage
        inspector {
          _id
          username
        }
        inspectedAt
      }
      createdAt
    }
  }
`;

export const EVENT_IMAGE_LIST = gql`
  query AdminEventImageList($params: ImageListParams) {
    adminEventImageList(params: $params) {
      _id
      totalCount
      list {
        _id
        event {
          _id
        }
        animal {
          _id
          name
          breed {
            _id
            name
          }
          owner {
            _id
          }
        }
        mediaFile {
          _id
          version
          format
          width
          height
        }
        inspection {
          status
          rejectMessage
          inspector {
            _id
            username
          }
          inspectedAt
        }
        createdAt
      }
    }
  }
`;

export const EVENT_IMAGE_INSPECTION_LIST = gql`
  query AdminEventImageInspectionList($params: ListParams) {
    adminEventImageInspectionList(params: $params) {
      _id
      totalCount
      list {
        _id
        event {
          _id
        }
        animal {
          _id
          name
          breed {
            _id
            name
          }
          owner {
            _id
          }
        }
        mediaFile {
          _id
          version
          format
          width
          height
          bytes
        }
        inspection {
          status
          rejectMessage
          inspector {
            _id
            username
          }
          inspectedAt
        }
        duplicates {
          _id
          event {
            _id
          }
          animal {
            _id
            name
            breed {
              _id
              name
            }
            owner {
              _id
            }
          }
          mediaFile {
            _id
            version
            format
          }
          inspection {
            status
            rejectMessage
            inspector {
              _id
              username
            }
            inspectedAt
          }
          createdAt
        }
        createdAt
      }
    }
  }
`;

export const EVENT_IMAGE_VIEW = gql`
  query AdminEventImageView($id: ID) {
    adminEventImageView(id: $id) {
      _id
      event {
        _id
      }
      animal {
        _id
        name
        breed {
          _id
          name
        }
        owner {
          _id
        }
      }
      mediaFile {
        _id
        version
        format
      }
      createdAt
    }
  }
`;

export const EVENT_IMAGE_INSPECT = gql`
  mutation AdminEventImageInspect($id: ID, $input: EventImageInspectInput) {
    adminEventImageInspect(id: $id, input: $input) {
      _id
      event {
        _id
      }
      animal {
        _id
        name
        breed {
          _id
          name
        }
        owner {
          _id
        }
      }
      mediaFile {
        _id
        version
        format
      }
      createdAt
    }
  }
`;

export const EVENT_ANIMAL_SEND_COUPON = gql`
  mutation AdminEventAnimalSendCoupon($id: ID) {
    adminEventAnimalSendCoupon(id: $id) {
      _id
      event {
        _id
      }
      animal {
        _id
        name
        breed {
          _id
          name
        }
        gender
        birthday
        neuter
        color
        feature
        adoptionDay
        arn
        owner {
          _id
        }
      }
      arOrg
      arCard {
        _id
        version
        format
      }
      inspection {
        status
        rejectMessage
        inspector {
          _id
          username
        }
        inspectedAt
      }
      couponSentAt
      createdAt
    }
  }
`;
