const messages = {
  About: 'App Information',
  Account: 'Account',
  Actions: 'Actions',
  Add: 'Add',
  AdditionalMessage: 'Additional Message',
  Address: 'Address',
  AddressEmptyMessage: 'No address',
  Adoptionday: 'Adoption date',
  Ai: 'Ai',
  AiData: 'Ai data',
  AiEvent: 'Ai event',
  AiEventName: 'Event name',
  Animal: 'Animal',
  AppInfo: 'About App',
  AppName: 'Fashion Data Admin',
  Ar: 'Animal register',
  ArCard: 'AR Card',
  Arn: 'no',
  ArOrg: 'Registering organization',
  Author: 'Author',
  Bank: 'Bank',
  BankAccount: 'Bank account',
  BankHolder: 'Bank holder',
  BankName: 'Bank name',
  Bookmark: 'Bookmark',
  Breed: 'Breed',
  Birthday: 'Birthday',
  Cancel: 'Cancel',
  CardName: 'Card name',
  CardNumber: 'Card number',
  CAT: 'Cat',
  Category: 'Category',
  CategoryEditDoneMessage: 'Category changed successfully.',
  CategoryNewDoneMessage: ' New category registered successfully.',
  ChangePassword: 'Change password',
  CheckDuplicates: 'Check duplicates',
  Claim: 'Claim',
  ClaimedAt: 'Claim date',
  ClaimComment: 'Claim comment',
  ClaimMessage: 'Claim message',
  ClaimPost: 'Claim post',
  ClaimUser: 'Claim user',
  Close: 'Close',
  Comment: 'Comment',
  Company: 'Company',
  Color: 'Color',
  Consulting: 'Consulting',
  ConsultingRequest: 'Consulting request',
  Count: 'Count',
  CountDays: 'Count days',
  CountPass: 'Count passed',
  CountQuestion: 'Count question',
  CountReject: 'Count rejected',
  CountTotal: 'Total: %{total}',
  CountTotalAndSelected: 'Total: %{total}, Selected: %{selected}',
  CountUpload: 'Count uploaded',
  CouponSent: 'Coupon sent',
  CouponSentAt: 'Coupon sent date',
  CouponSentMessage: 'Coupon sent to "%{name}" successfully.',
  CreatedAt: 'CreatedAt',
  CS: 'Customer service',
  CurrentPassword: 'Current password',
  Date: 'Date',
  Dashboard: 'Dashboard',
  DashboardRealTime: 'RealTime (Today)',
  DashboardTotal: 'Total (Yesterday)',
  DeleteConfirmMessage: 'Delete the selected %{count} item(s)?',
  Delivery: 'Delivery',
  DeliveryCode: 'Delivery code',
  DeliveryCodeDoneMessage: 'Delivery code created successfully.',
  DeliveryCompany: 'Delivery company',
  DeliveryRequest: 'Delivery request',
  DeliveryUrl: 'Delivery url',
  Description: 'Description',
  Details: 'Details',
  DeliveryFee: 'Delivery fee',
  DOG: 'Dog',
  Duplicates: 'Duplicates',
  EditName: 'Edit name',
  EngName: 'English name',
  EmailAddress: 'Email address',
  EmptyListMessage: 'No data',
  EventData: 'Event data',
  EventUser: 'Event user',
  FaceId: 'Face ID',
  Faq: 'FAQ',
  Feature: 'Feature',
  Feedback: 'Feedback',
  FeeThreshold: 'FeeThreshold',
  Female: 'Female',
  Finished: 'Finished',
  FirstName: 'First name',
  ForgotPassword: 'Forgot password?',
  ForgotPasswordDoneMessage: 'The mail for reset password sent successfully.',
  Gender: 'Gender',
  General: 'General',
  GuideUrl: 'Guide url',
  Hidden: 'Hidden',
  HiddenDoneMessage: 'Status change successfully.',
  Home: 'Home',
  HomeUrl: 'Home url',
  ID: 'ID',
  ImageDetected: 'Image detected',
  ImageOriginal: 'Image original',
  ImageSize: 'Image size',
  ImageList: 'Image list',
  Inspection: 'Inspection',
  InspectionReady: 'Ready',
  InspectionPass: 'Pass',
  InspectionReject: 'Reject',
  InspectionSuccessMessage: 'Inspection completed successfully.',
  InputBreedKeywords: 'Input breed keywords',
  InputDeliveryCode: 'Input delivery code',
  InputOptionName: 'Input option name',
  InputOptionPrice: 'Input option value price',
  InputOptionValue: 'Input option value',
  Insert: 'Insert',
  JuminNumber: 'Resident registration number',
  Keyword: 'Keywords',
  LeavedAt: 'Withdrawn date',
  LastName: 'Last name',
  List: 'List',
  Like: 'Like',
  Location: 'Location',
  Manager: 'Manager',
  Male: 'Male',
  Mall: 'Mall',
  Match: 'Match',
  Mediafile: 'Mediafile',
  Message: 'Message',
  MileageAdd: 'Mileage add',
  MileageFree: 'Mileage free',
  MileageUse: 'Mileage use',
  MoveToHome: 'Move to Home screen',
  Name: 'Name',
  NameChangedMessage: 'Name changed successfully.',
  Neuter: 'Neuter',
  NeuterNo: 'No neuter',
  NeuterYes: 'Neuter',
  NewPassword: 'New password',
  NickName: 'Nick name',
  No: 'No',
  NoMediafiles: 'No mediafiles',
  Notice: 'Notice',
  NotMatch: 'Not match',
  NotQualified: 'Not qualified',
  Open: 'Open',
  Order: 'Order',
  OrderCode: 'Order code',
  OrderItem: 'OrderItem',
  OrderPrice: ' Order price',
  OrderUser: 'Order user',
  OrderedAt: 'OrderedAt',
  Origin: 'Origin',
  OptionValueHelpMessage: 'Input one value. ex)S,M,L...',
  Options: 'Options',
  OptionValue: 'Option value',
  OptionsPrice: 'Prices',
  OptionYes: 'Option yes',
  OptionNo: 'Option no',
  Owner: 'Owner',
  Okay: 'Okay',
  Partner: 'Contractor',
  Pass: 'Pass',
  Password: 'Password',
  PasswordChangedMessage: 'Password changed successfully.',
  PasswordConfirm: 'Password repeat',
  Pay: 'Pay',
  PayCancel: 'Pay cancel',
  PayCancelMessage: 'Pay cancel?',
  PayCancelDoneMessage: 'Pay cancel successfully.',
  PayMethod: 'Pay method',
  PayPrice: 'Pay price',
  PayTid: 'Pay tid',
  PayReceiptUrl: 'Pay receiptUrl',
  PetOwner: 'Pet owner',
  Phone: 'Phone',
  PhoneType: 'Phone type',
  PinLevel: 'PinLevel',
  PinLevelChangeDoneMessage: 'Pin level is changed',
  Post: 'Post',
  Point: 'Point',
  PostClick: 'Look',
  Position: 'Position',
  Price: 'Price',
  Preview: 'Preview',
  Product: 'Product',
  ProductCode: 'Code',
  ProductStatusChangeDoneMessage: 'Status changed successfully.',
  ProductMileageChangeDoneMessage: 'MileageFree changed successfully.',
  ProductName: 'Product name',
  ProductNewDoneMessage: 'New product registered successfully.',
  Profile: 'Profile',
  Ready: 'Ready',
  Reciver: 'Reciver',
  Register: 'Register',
  RetailPrice: 'Retail price',
  Reject: 'Reject',
  RejectMessage: 'Reject message',
  Remove: 'Remove',
  RequiredValue: 'Required',
  Reservation: 'Reservation',
  ResetPassword: 'ResetPassword',
  ResetPasswordDoneMessage: 'Password was reset successfully.',
  Role: 'Role',
  RoleEditDoneMessage: 'The role is updated successfully.',
  RoleNewDoneMessage: 'New role registered successfully.',
  RoleRemoveConfirmMessage: 'Are you going to remove the role?',
  Save: 'Save',
  Season: 'Season',
  SelectRejectMessage: 'Select reject message',
  Send: 'Send',
  Settings: 'Settings',
  Share: 'Share',
  SignIn: 'Sign in',
  SignInChannel: 'Sign in channel',
  SignUp: 'Sign up',
  SignOut: 'Sign out',
  SignOutConfirmMessage: 'Are you going to sign out?',
  SignUpDone: 'Sign-up done',
  SignUpDoneMessage:
    'Signed up successfully. Check email and activate account.',
  Species: 'Species',
  Staff: 'Staff',
  StatDaily: 'Daily stat',
  StatTotal: 'Total stat',
  Status: 'Status',
  StatusUpdateDoneMessage: 'Status update done',
  Stock: 'Stock',
  Subtitle: 'Subtitle',
  Submit: 'Submit',
  SupplyPrice: 'Supply price',
  Supplier: 'Supplier',
  System: 'System',
  SystemInfo: 'System information',
  SystemSettings: 'System settings',
  Title: 'Title',
  Theme: 'Theme',
  Today: 'Today',
  TotalCount: 'Total count: %{count}',
  Update: 'Update',
  UpdateDoneMessage: 'Update done',
  Upload: 'Upload',
  User: 'User',
  UserActivation: 'User activation',
  UserDeactivated: 'User deactivated',
  UserDeleteConfirmMessage: 'Delete the selected %{count} users?',
  UserLeaved: 'Withdrawn User',
  Username: 'Username',
  UserVerificationFailedMessage:
    'Account was NOT activated. The input token is invalid. Check it and try again.',
  UserVerifiedMessage: 'Sign up done and activated the account.',
  Verification: 'Verification',
  VerificationDistance: 'Verification distance',
  Yes: 'Yes',
  WelcomeMessage: 'Welcome!',
};

export default messages;
