import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { IListParams, IPagination, ISortBy } from 'face-id-types';

import { PAGINATION_DEFAULT } from '../../config/constants';
import { filterGraphQLErrors, updateSort } from '../../lib';
import { EVENT_USER_LIST } from '../../graphql';
import UserList from './UserList';

const UserListContainer = () => {
  const history = useHistory();

  const [listParams, setListParams] = useState<IListParams>({
    skip: PAGINATION_DEFAULT.page * PAGINATION_DEFAULT.rowsPerPage,
    limit: PAGINATION_DEFAULT.rowsPerPage,
    sortBy: [{ field: 'createdAt', order: 'DESC' }],
  });

  const { loading, error, data } = useQuery(EVENT_USER_LIST, {
    variables: { params: listParams },
  });

  const handleAction = (command: string, params?: unknown) => {
    let updatedSort;
    switch (command) {
      case 'EDIT':
        history.push(`/users/${params}/edit`);
        break;

      case 'DELETE':
        console.log('DELETE', params);
        break;

      case 'PAGE':
        setListParams({
          ...listParams,
          skip: (params as IPagination).page * listParams.limit,
        });
        break;

      case 'ROWS_PER_PAGE':
        setListParams({
          ...listParams,
          skip: 0,
          limit: (params as IPagination).rowsPerPage,
        });
        break;

      case 'SORT':
        updatedSort = updateSort(listParams.sortBy, params as ISortBy);
        setListParams({
          ...listParams,
          skip: 0,
          sortBy: updatedSort,
        });

        break;

      default:
    }
  };

  return (
    <UserList
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminEventUserList?.list || []}
      sort={listParams.sortBy}
      pagination={{
        count: data?.adminEventUserList?.totalCount || 0,
        page: Math.floor(listParams.skip / listParams.limit),
        rowsPerPage: listParams.limit,
        rowsPerPageOptions: PAGINATION_DEFAULT.rowsPerPageOptions,
      }}
      onAction={handleAction}
    />
  );
};

export default UserListContainer;
