const status = {
  CARD: '카드',
  CANCEL: '주문취소',
  KAKAO_PAY: '카카오페이',
  READY: '주문진행중',
  PAY_READY: '결제대기',
  PAID: '결제완료',
  PAID_CONFIRM: '상품 준비중',
  ON_DELIVERY: '배송중',
  DELIVERY_DONE: '배송완료',
  ORDER_DONE: '구매확정',
  CONSULTING_STATUS_REQUEST: '상담 신청',
  CONSULTING_STATUS_RESPONSE: '상담 응답(통화)',
  CONSULTING_STATUS_RESERVED: '방문 예약',
  CONSULTING_STATUS_VISITED: '방문 상담',
  CONSULTING_STATUS_CONTRACTED: '입양 계약',
  CONSULTING_STATUS_ADOPTED: '입양 인도',
  CONSULTING_STATUS_CANCELED: '중도 취소',
  CONSULTING_STATUS_FINISHED: '상담 종료',
  INSPECTION_STATUS_READY: '대기',
  INSPECTION_STATUS_PASS: '통과',
  INSPECTION_STATUS_REJECT: '반려',
  USER_STATUS_ACTIVE: '정상',
  USER_STATUS_HOLD: '일시중지',
  USER_STATUS_INACTIVATED: '비활성',
  USER_STATUS_INTERIM: '회원가입 진행중',
  USER_STATUS_LEAVING: '탈퇴 요청',
  USER_STATUS_LEAVED: '탈퇴',
};

export default status;
