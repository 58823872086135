import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Logout as LogoutIcon } from '@mui/icons-material';

import { SessionContext } from '../context';
import { getUserAvatar, getUserName, t } from '../lib';
import { Confirm } from '../components';

interface Props {
  onProfile: () => void;
  onSignOut: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  profileText: {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.primary
        : theme.palette.primary.contrastText,
  },
}));

const AccountBar = ({ onProfile, onSignOut }: Props) => {
  const classes = useStyles();
  const [confirm, setConfirm] = useState<boolean>(false);

  const handleSignOut = () => {
    setConfirm(true);
  };

  const handleConfirm = (answer: boolean) => {
    setConfirm(false);
    onSignOut(answer);
  };

  return (
    <Box className={classes.container}>
      <SessionContext.Consumer>
        {(sessionContext) => (
          <Toolbar>
            <Link component="button" underline="none" onClick={onProfile}>
              <Avatar>
                {sessionContext?.account &&
                  getUserAvatar(sessionContext.account)}
              </Avatar>
            </Link>
            <Button sx={{ marginLeft: 1 }} variant="text" onClick={onProfile}>
              <Typography className={classes.profileText}>
                {sessionContext?.account && getUserName(sessionContext.account)}
              </Typography>
            </Button>
          </Toolbar>
        )}
      </SessionContext.Consumer>
      <IconButton aria-label="logout" color={'inherit'} onClick={handleSignOut}>
        <LogoutIcon />
      </IconButton>
      <Confirm
        open={confirm}
        title={t('SignOut')}
        message={t('SignOutConfirmMessage')}
        onClose={handleConfirm}
      />
    </Box>
  );
};

export default AccountBar;
