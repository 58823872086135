import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { IEventAnimal, IListParams, IPagination, ISortBy } from 'face-id-types';

import { PAGINATION_DEFAULT } from '../../config/constants';
import { filterGraphQLErrors, updateSort } from '../../lib';
import { PETMILY_EVENT_ANIMAL_LIST } from '../../graphql';
import AnimalList from './AnimalList';

const AnimalListContainer = () => {
  const history = useHistory();

  const [listParams, setListParams] = useState<IListParams>({
    skip: PAGINATION_DEFAULT.page * PAGINATION_DEFAULT.rowsPerPage,
    limit: PAGINATION_DEFAULT.rowsPerPage,
    sortBy: [{ field: 'createdAt', order: 'DESC' }],
  });

  const { loading, error, data, refetch } = useQuery(
    PETMILY_EVENT_ANIMAL_LIST,
    {
      variables: { params: listParams },
    },
  );

  const handleAction = (command: string, params?: unknown) => {
    let updatedSort;

    const pagination = params as IPagination;
    switch (command) {
      case 'INSPECT':
        break;

      case 'EDIT':
        break;

      case 'DELETE':
        console.log('DELETE', params);
        break;

      case 'PAGE':
        setListParams({
          ...listParams,
          skip: pagination.page * listParams.limit,
        });
        break;

      case 'ROWS_PER_PAGE':
        setListParams({
          ...listParams,
          skip: 0,
          limit: pagination.rowsPerPage,
        });

        break;

      case 'SORT':
        updatedSort = updateSort(listParams.sortBy, params as ISortBy);
        setListParams({
          ...listParams,
          skip: 0,
          sortBy: updatedSort,
        });
        break;

      default:
    }
  };

  return (
    <AnimalList
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminPetmilyEventAnimalList?.list || []}
      sort={listParams.sortBy}
      pagination={{
        count: data?.adminPetmilyEventAnimalList?.totalCount || 0,
        page: Math.floor(listParams.skip / listParams.limit),
        rowsPerPage: listParams.limit,
        rowsPerPageOptions: PAGINATION_DEFAULT.rowsPerPageOptions,
      }}
      onAction={handleAction}
    />
  );
};

export default AnimalListContainer;
