import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { filterGraphQLErrors } from '../../lib';
import { QNA_ANSWER, QNA_VIEW } from '../../graphql';
import QnaView from './View';

interface Props {
  id: string;
}

const QnaViewContainer = () => {
  const { id } = useParams<Props>();

  const { loading, error, data, refetch } = useQuery(QNA_VIEW, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  const [qnaAnswer, { loading: submitting, error: errorAnswer }] = useMutation(
    QNA_ANSWER,
    {
      onCompleted: (response) => {
        if (!response.adminQnaAnswer) {
          return;
        }

        refetch();
      },
    },
  );

  const handleAction = (command: string, params: any) => {
    if (command === 'SUBMIT') {
      qnaAnswer({
        variables: {
          input: {
            id,
            message: params.message,
          },
        },
      });
    }
  };

  return (
    <QnaView
      loading={loading}
      errors={filterGraphQLErrors(error)}
      data={data?.adminQnaView}
      submitting={submitting}
      onAction={handleAction}
    />
  );
};

export default QnaViewContainer;
