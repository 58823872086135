import React from 'react';
import { IEventImage, InspectionStatusType } from 'face-id-types';
import { useMutation, useQuery } from '@apollo/client';

import { DICTIONARY_LIST, EVENT_IMAGE_INSPECT } from '../../graphql';
import Inspect from './Inspect';
import { Loading } from '../../components';

interface Props {
  image: IEventImage | undefined;
  onClose: (refresh: boolean) => void;
}

const InspectContainer = ({ image, onClose }: Props) => {
  const { data, loading, error } = useQuery(DICTIONARY_LIST, {
    variables: {
      categories: ['ANIMAL_IMAGE_INSPECTION_REJECT'],
      input: {
        skip: 0,
      },
    },
  });

  const [inspect, { loading: inspectLoading, error: inspectError }] =
    useMutation(EVENT_IMAGE_INSPECT, {
      onCompleted: (response) => {
        console.log('EVENT_IMAGE_INSPECT', response);
        if (!response.adminEventImageInspect) return;

        onClose(true);
      },
    });
  if (inspectError) {
    console.error('inspectError', inspectError);
  }

  const handleSubmit = (
    id: string,
    status: InspectionStatusType,
    rejectMessage?: string,
  ) => {
    inspect({ variables: { id, input: { status, rejectMessage } } });
  };

  return loading ? (
    <Loading />
  ) : (
    <Inspect
      image={image}
      rejectMessages={data?.dictionaryList}
      onSubmit={handleSubmit}
      onClose={() => onClose(false)}
    />
  );
};

export default InspectContainer;
