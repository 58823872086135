import React, { ChangeEvent, useState } from 'react';
import { Button, Typography } from '@mui/material';

import {
  PET_LIST,
  PET_VIEW,
  PET_NEW,
  PET_REMOVE,
  PET_UPDATE,
} from '../../graphql';
// import { Table } from '../../components';
import { t, getDate } from '../../lib';
import { Page } from '../../layout';

const List = () => {
  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'reservedDt', label: 'reservedDt' },
    { id: 'desc', label: 'Description' },
  ];

  const form = {
    name: 'Pet',
    crud: {
      c: PET_NEW,
      r: PET_VIEW,
      u: PET_UPDATE,
      d: PET_REMOVE,
    },
    columns: [
      { id: '_id', label: 'ID' },
      { id: 'name', label: 'Name' },
      { id: 'email', label: 'Email' },
      { id: 'birthday', label: 'birthday' },
      { id: 'phone', label: 'phone' },
      { id: 'reservedDt', label: 'reservedDt' },
      { id: 'addr', label: 'addr' },
      { id: 'request', label: 'request' },
      { id: 'memo', label: 'memo' },
    ],
  };

  return (
    <Page
      title={t('Pet')}
      pageTitle={
        <Typography variant="h4">
          {t('Pet')} <small>{t('List')}</small>
        </Typography>
      }
    >
      {/* <Table headers={headers} form={form} query={{ petList: PET_LIST }} /> */}
    </Page>
  );
};

export default List;
