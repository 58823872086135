import { gql } from '@apollo/client';

export const FACE_ID_EMBED_LIST = gql`
  query adminFaceIdEmbedList($params: ListParams) {
    adminFaceIdEmbedList(params: $params) {
      _id
      totalCount
      list {
        _id
        animal {
          _id
          name
          owner {
            _id
            username
          }
        }
        data {
          detection {
            version
            s3File {
              bucket
              key
              url
            }
            result
            duration
          }
          embedding {
            version
            s3File {
              bucket
              key
              url
            }
            result
            duration
          }
        }
        createdAt
      }
    }
  }
`;

export const FACE_ID_VERIFY_LIST = gql`
  query adminFaceIdVerifyList($params: ListParams) {
    adminFaceIdVerifyList(params: $params) {
      _id
      totalCount
      list {
        _id
        animal {
          _id
          name
        }
        user {
          _id
          username
        }
        data {
          detection {
            version
            s3File {
              bucket
              key
              url
            }
            result
            distance
            duration
          }
          verification {
            version
            s3File {
              bucket
              key
              url
            }
            result
            distance
            duration
          }
          others {
            version
            s3File {
              bucket
              key
              url
            }
            result
            distance
            duration
            animal {
              _id
            }
          }
        }
        feedback {
          match
          details
        }
        createdAt
      }
    }
  }
`;
