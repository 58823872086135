import React, { useRef, useState } from 'react';
import { Box, Link, Typography, List, ListItem } from '@mui/material';
import dayjs from 'dayjs';
import {
  IError,
  IEventAnimal,
  IEventUser,
  IEvent,
  IAnimal,
  IMediaFile,
  IInspection,
  IPagination,
  IEventDataStat,
  ISortBy,
  InspectionStatus,
} from 'face-id-types';

import { Confirm, DataTable, Modal } from '../../components';
import { findSortOrder, t, phoneFormat } from '../../lib';
import { Page } from '../../layout';
import AnimalView from './AnimalView';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IEventAnimal[];
  sort?: ISortBy[];
  pagination?: IPagination;
  submitting?: boolean;
  onAction?: (command: string, params?: unknown) => void;
}

interface ActionParams {
  command: string;
  params: unknown;
}

interface EventAnimalPrps {
  _id: string;
  event: IEvent;
  animal: IAnimal;
  arOrg: string;
  user: IEventUser;
  arCard: IMediaFile;
  inspection: IInspection;
  stat: IEventDataStat;
  couponSentAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

const AnimalList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  submitting,
  onAction,
}: Props) => {
  const actionArgs = useRef<ActionParams>();

  const [viewAnimal, setViewAnimal] = useState<IEventAnimal | undefined>(
    undefined,
  );
  const [viewUser, setViewUser] = useState<IEventUser | undefined>(undefined);
  const [viewImages, setViewImages] = useState<any>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const headers = [
    {
      id: 'name',
      label: t('Name'),
      sort: findSortOrder(sort, 'animal.name'),
      getValue: (obj: IEventAnimal) => {
        return (
          <Link
            component="button"
            onClick={() => {
              setViewAnimal(obj);
            }}
          >
            {obj.animal.name}
          </Link>
        );
      },
    },
    {
      id: 'breed',
      label: t('Breed'),
      sort: findSortOrder(sort, 'animal.breed.name'),
      getValue: (obj: IEventAnimal) => obj.animal.breed.name,
    },
    {
      id: 'arn',
      label: t('Arn'),
      sort: findSortOrder(sort, 'animal.arn'),
      getValue: (obj: IEventAnimal) => obj.animal.arn,
    },
    {
      id: 'owner',
      label: t('PetOwner'),
      sort: findSortOrder(sort, 'animal.owner.username'),
      getValue: (obj: any) => (
        <Link
          component="button"
          onClick={() => {
            setViewUser(obj?.user);
          }}
        >
          {obj.animal.owner.username}
        </Link>
      ),
    },
    {
      id: 'countUpload',
      label: t('CountUpload'),
      getValue: (obj: any) => (
        <Link
          component="button"
          onClick={() => {
            setViewImages(obj?.embeddings);
          }}
        >
          {obj?.stat?.upload}
        </Link>
      ),
    },
    // {
    //   id: 'countPass',
    //   label: t('CountPass'),
    //   getValue: (obj: IEventAnimal) => (
    //     <Link
    //       component="button"
    //       onClick={() => {
    //         setViewImages({
    //           animalId: obj?.animal?._id.toString(),
    //           status: InspectionStatus.PASS,
    //         });
    //       }}
    //     >
    //       {obj?.stat?.upload}
    //     </Link>
    //   ),
    // },
    {
      id: 'countPass',
      label: t('CountPass'),
      getValue: (obj: IEventAnimal) => obj?.stat?.pass,
    },
    // {
    //   id: 'countDays',
    //   label: t('CountDays'),
    //   getValue: (obj: IEventAnimal) => (
    //     <Link
    //       component="button"
    //       onClick={() => {
    //         setViewImages({
    //           animalId: obj?.animal?._id.toString(),
    //           status: InspectionStatus.REJECT,
    //         });
    //       }}
    //     >
    //       {obj?.stat?.days}
    //     </Link>
    //   ),
    // },
    // {
    //   id: 'couponSentAt',
    //   label: t('CouponSentAt'),
    //   getValue: (obj: IEventAnimal) => {
    //     if (obj.stat.pass >= 0) {
    //       return obj.couponSentAt ? (
    //         dayjs(obj.couponSentAt).format('YYYY-MM-DD')
    //       ) : (
    //         <LoadingButton
    //           loading={submitting}
    //           onClick={() => {
    //             onAction('SENT_COUPON', obj);
    //           }}
    //         >
    //           {t('Send')}
    //         </LoadingButton>
    //       );
    //     } else {
    //       return t('NotQualified');
    //     }
    //   },
    // },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IEventAnimal) =>
        dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: unknown) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('Animal')}
      pageTitle={
        <Typography variant="h4">
          {t('Animal')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IEventAnimal) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: (actionArgs.current?.params as unknown[])?.length,
        })}
        onClose={handleConfirm}
      />
      <AnimalView
        open={!!viewAnimal}
        eventAnimal={viewAnimal}
        onClose={() => {
          setViewAnimal(undefined);
        }}
      />
      <Modal
        open={!!viewImages}
        title={t('ImageList')}
        onClose={() => {
          setViewImages(undefined);
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {viewImages?.map((item: any) => (
            <img
              key={item._id.toString()}
              src={item?.data?.[0].detection.s3File.url}
              style={{ width: 'auto', height: 300 }}
            />
          ))}
        </Box>
      </Modal>
      {/* <UserView
        open={!!viewUser}
        eventUser={viewUser}
        onClose={() => {
          setViewUser(undefined);
        }}
      /> */}
      <Modal
        open={!!viewUser}
        // title={viewUser?.phoneAuth?.name}
        onClose={() => {
          setViewUser(undefined);
        }}
      >
        <List>
          <ListItem
            secondaryAction={
              <Typography>{viewUser?._id.toString()}</Typography>
            }
          >
            <Typography variant="body1">ID</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>{viewUser?.phoneAuth?.name}</Typography>
            }
          >
            <Typography variant="body1">{t('Name')}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>
                {viewUser?.phoneAuth?.phoneNumber &&
                  phoneFormat(viewUser.phoneAuth.phoneNumber)}
              </Typography>
            }
          >
            <Typography variant="body1">{t('Phone')}</Typography>
          </ListItem>
        </List>
      </Modal>
    </Page>
  );
};

export default AnimalList;
