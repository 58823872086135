import React from 'react';
import { Typography } from '@mui/material';

import { t } from '../../lib';
import { Page } from '../../layout';
import { APP } from '../../config/constants';

const About = () => {
  return (
    <Page
      title={t('About')}
      pageTitle={<Typography variant="h4">{t('About')}</Typography>}
    >
      <Typography variant="h5">{t('AppName')}</Typography>
      <Typography variant="body1">version: {APP.version}</Typography>
      <Typography variant="body1">&copy; 2022 BlockPet Co., Ltd.</Typography>
    </Page>
  );
};

export default About;
