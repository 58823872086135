import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { IError, IPagination, ISortBy, IUser } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IUser[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
}

const UserList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const actionArgs = useRef<any>();

  const headers = [
    {
      id: 'username',
      label: t('Username'),
      sort: findSortOrder(sort, 'username'),
      getValue: (obj: IUser) => obj.username,
    },
    {
      id: 'emails.0.address',
      label: t('EmailAddress'),
      getValue: (obj: IUser) => obj.emails?.[0].address,
    },
    {
      id: 'general.phone',
      label: t('Phone'),
      getValue: (obj: IUser) => obj.general?.phones?.[0].number,
    },
    {
      id: 'status',
      label: t('Status'),
      sort: findSortOrder(sort, 'status'),
      getValue: (obj: IUser) => t(`USER_STATUS_${obj.status}`),
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IUser) => dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('Account')}
      pageTitle={
        <Typography variant="h4">
          {t('Account')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IUser) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        actions={[{ command: 'NEW', Icon: <PersonAddIcon /> }]}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: actionArgs.current?.params?.length,
        })}
        onClose={handleConfirm}
      />
    </Page>
  );
};

export default UserList;
