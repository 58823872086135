import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { t } from '../lib';

interface Props {
  open: boolean;
  title?: string;
  message: string;
  onClose: (answer: boolean) => void;
}

const Confirm = ({ open, title, message, onClose }: Props) => {
  const handleClose = (answer: boolean) => {
    onClose(answer);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
          autoFocus
        >
          {t('No')}
        </Button>
        <Button
          onClick={() => {
            handleClose(true);
          }}
        >
          {t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
