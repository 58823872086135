import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { filterGraphQLErrors, t } from '../../lib';
import {
  DICTIONARY_LIST,
  EVENT_ANIMAL_INSPECT,
  EVENT_ANIMAL_VIEW,
} from '../../graphql';
import AnimalInspect from './AnimalInspect';

interface Props {
  id: string;
}

const AnimalInspectContainer = () => {
  const { id } = useParams<Props>();

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: rejectMessages } = useQuery(DICTIONARY_LIST, {
    variables: {
      category: 'ANIMAL_INSPECTION_REJECT',
      input: { skip: 0, limit: 100 },
    },
  });

  const { loading, error, data } = useQuery(EVENT_ANIMAL_VIEW, {
    variables: { id },
  });

  const [eventAnimalInspect, { loading: loadingInspect, error: errorInspect }] =
    useMutation(EVENT_ANIMAL_INSPECT, {
      onCompleted: (response) => {
        if (!response?.adminEventAnimalInspect) {
          return;
        }
        enqueueSnackbar(t('InspectionSuccessMessage'));
        history.goBack();
      },
    });
  if (errorInspect) {
    console.log('errorInspect', errorInspect);
  }

  const handleAction = (command: string, params?: unknown) => {
    switch (command) {
      case 'BACK':
        history.goBack();
        break;

      case 'SUBMIT':
        console.log('SUBMIT', params);
        eventAnimalInspect({
          variables: {
            id,
            input: params,
          },
        });
        break;

      default:
    }
  };

  return (
    <AnimalInspect
      loading={loading}
      errors={filterGraphQLErrors(error)}
      eventAnimal={data?.adminEventAnimalView}
      rejectMessages={rejectMessages?.dictionaryList}
      onAction={handleAction}
    />
  );
};

export default AnimalInspectContainer;
