import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { IEventAnimal, IListParams, IPagination, ISortBy } from 'face-id-types';
import { useSnackbar } from 'notistack';

import { PAGINATION_DEFAULT } from '../../config/constants';
import { filterGraphQLErrors, t, updateSort } from '../../lib';
import { EVENT_ANIMAL_LIST, EVENT_ANIMAL_SEND_COUPON } from '../../graphql';
import AnimalList from './AnimalList';

const AnimalListContainer = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [listParams, setListParams] = useState<IListParams>({
    skip: PAGINATION_DEFAULT.page * PAGINATION_DEFAULT.rowsPerPage,
    limit: PAGINATION_DEFAULT.rowsPerPage,
    sortBy: [{ field: 'createdAt', order: 'DESC' }],
  });

  const { loading, error, data, refetch } = useQuery(EVENT_ANIMAL_LIST, {
    variables: { params: listParams },
  });

  const [
    adminEventAnimalSendCoupon,
    { loading: submitting, error: errorSend },
  ] = useMutation(EVENT_ANIMAL_SEND_COUPON, {
    onCompleted: (response) => {
      if (!response.adminEventAnimalSendCoupon) return;

      refetch();
      enqueueSnackbar(
        t('CouponSentMessage', {
          name: response.adminEventAnimalSendCoupon?.animal?.name,
        }),
      );
    },
  });
  if (errorSend) {
    console.log('errorSend', errorSend);
  }

  const handleAction = (command: string, params?: unknown) => {
    let updatedSort;
    const pagination = params as IPagination;
    switch (command) {
      case 'INSPECT':
        history.push(
          `/data-event-animals/${(params as IEventAnimal)._id}/inspect`,
        );
        break;

      case 'EDIT':
        history.push(`/data-event-animals/${params}/edit`);
        break;

      case 'DELETE':
        console.log('DELETE', params);
        break;

      case 'SENT_COUPON':
        if (!submitting) {
          adminEventAnimalSendCoupon({
            variables: { id: (params as IEventAnimal)?._id.toString() },
          });
        }
        break;

      case 'PAGE':
        setListParams({
          ...listParams,
          skip: pagination.page * listParams.limit,
        });
        break;

      case 'ROWS_PER_PAGE':
        setListParams({
          ...listParams,
          skip: 0,
          limit: pagination.rowsPerPage,
        });
        break;

      case 'SORT':
        updatedSort = updateSort(listParams.sortBy, params as ISortBy);
        setListParams({
          ...listParams,
          skip: 0,
          sortBy: updatedSort,
        });
        break;

      default:
    }
  };

  return (
    <AnimalList
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminEventAnimalList?.list || []}
      sort={listParams.sortBy}
      pagination={{
        count: data?.adminEventAnimalList?.totalCount || 0,
        page: Math.floor(listParams.skip / listParams.limit),
        rowsPerPage: listParams.limit,
        rowsPerPageOptions: PAGINATION_DEFAULT.rowsPerPageOptions,
      }}
      submitting={submitting}
      onAction={handleAction}
    />
  );
};

export default AnimalListContainer;
