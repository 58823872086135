/**
 * 화면에 대한 Context 설정을 수행한다.
 * - 레이아웃, 레이아웃에서 헤더의 유무 여부
 * - 화면의 제목
 * - 화면의 Helmet 설정
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { LOCAL_STORAGE_KEY } from '../config/constants';
import { IHelmet, IUIContext, LayoutType, ThemeModeType } from '../types';

interface ProviderProps {
  children: ReactNode;
}

interface ContextProps extends IUIContext {
  setValues?: (values: IUIContext) => void;
}

const UI_CONTEXT_DEFAULT: IUIContext = {
  theme: 'auto',
  layout: 'FULL_WIDTH',
  title: '',
  helmet: {
    title: '',
    description: '',
  },
};

export const UIContext = createContext<ContextProps>(UI_CONTEXT_DEFAULT);

export const UIContextProvider = ({ children }: ProviderProps) => {
  const [theme, setTheme] = useState<ThemeModeType>(UI_CONTEXT_DEFAULT.theme);
  const [layout, setLayout] = useState<LayoutType | undefined>(
    UI_CONTEXT_DEFAULT.layout,
  );
  const [title, setTitle] = useState<string | undefined>('');
  const [helmet, setHelmet] = useState<IHelmet | undefined>({ title: '' });
  const [headerLeft, setHeaderLeft] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [headerRight, setHeaderRight] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  useEffect(() => {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME_MODE);
    if (!savedTheme || theme === savedTheme) return;

    setTheme(savedTheme as ThemeModeType);
  }, []);

  const setValues = (values: IUIContext) => {
    values?.theme && setTheme(values?.theme);
    values?.layout && setLayout(values?.layout);
    values?.title && setTitle(values?.title);
    values?.helmet && setHelmet(values?.helmet);
    values?.headerLeft && setHeaderLeft(values?.headerLeft);
    values?.headerRight && setHeaderRight(values?.headerRight);
  };

  return (
    <UIContext.Provider
      value={{
        theme,
        layout,
        headerLeft,
        headerRight,
        title,
        helmet,
        setValues,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
