import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import {
  CheckOutlined as CheckOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
} from '@mui/icons-material';

import { VALIDATE_USERNAME } from '../graphql';

interface Props {
  name: string;
  value?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  autoComplete?: string;
  onChange: (value: string, valid: boolean) => void;
}

const InputUsername = ({
  name,
  value,
  label,
  error,
  helperText,
  fullWidth,
  autoComplete,
  onChange,
}: Props) => {
  const controlId = `outlined-validate-username-${name}`;

  const [username, setUsername] = useState<
    { value: string; valid: boolean } | { value: ''; valid: false }
  >();

  const [validateUsername, { loading, error: errorValidate }] = useMutation(
    VALIDATE_USERNAME,
    {
      onCompleted: (response) => {
        const valid = response?.validateUsername === 1;
        setUsername({ ...username, valid });
        onChange(username.value, valid);
      },
    },
  );
  if (errorValidate) {
    console.log('errorValidate', errorValidate);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!loading) {
      validateUsername({ variables: { username: value } });
    }
    setUsername({ ...username, value });
    onChange(value, username?.valid);
  };

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
      {label ? <InputLabel htmlFor={controlId}>{label}</InputLabel> : null}
      <OutlinedInput
        id={controlId}
        name={name}
        value={username?.value}
        autoComplete={autoComplete}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              {username?.valid ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        }
        label={label && helperText}
      />
      {helperText && (
        <FormHelperText id="outlined-weight-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputUsername;
