import React, { createRef, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Paper, Typography } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { IError } from 'face-id-types';

import { t, formatDateTime } from '../../lib';
import { Page } from '../../layout';
import { Button, InputText, Loading } from '../../components';

interface Props {
  loading: boolean;
  errors?: IError[];
  data?: any;
  submitting?: boolean;
  onAction: (command: string, params: any) => void;
}

const styles = {
  container: {
    width: '100%',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  messagesBody: {
    width: 'calc(100% - 20px)',
    margin: 1,
    overflowY: 'scroll',
    height: 'calc(100% - 80px)',
  },
  messageQuestion: {
    position: 'relative',
    marginLeft: '20px',
    marginRight: 'auto',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: 'white',
    width: '60%',
    textAlign: 'left',
    font: "400 .9em 'Open Sans', sans-serif",
    border: '1px solid #97C6E3',
    borderRadius: '10px',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '15px solid white',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      top: '0',
      left: '-15px',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '17px solid #97C6E3',
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      top: '-1px',
      left: '-17px',
    },
  },
  messageAnswer: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: '20px',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#A8DDFD',
    width: '60%',
    //height: "50px",
    textAlign: 'left',
    font: "400 .9em 'Open Sans', sans-serif",
    border: '1px solid #97C6E3',
    borderRadius: '10px',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '15px solid #A8DDFD',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      top: '0',
      right: '-15px',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '17px solid #97C6E3',
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      top: '-1px',
      right: '-17px',
    },
  },
  dateTime: {
    color: 'gray',
  },
  input: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
    padding: 2,
  },
  inputText: {
    flex: 1,
    marginRight: 2,
  },
  label: { width: 160, paddingRight: 1, textAlign: 'right' },
};

const QnaView = ({ loading, errors, data, submitting, onAction }: Props) => {
  const messageBottomRef = createRef<any>();

  const [message, setMessage] = useState('');

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const scrollToBottom = () => {
    messageBottomRef?.current &&
      messageBottomRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (!submitting) {
      onAction('SUBMIT', { message });
      setTimeout(() => {
        setMessage('');
      }, 100);
    }
  };

  return (
    <Page
      title={t('Qna')}
      pageTitle={<Typography variant="h4">{t('Qna')}</Typography>}
    >
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Typography variant="h5">
            {data?.author?.username}: {data?.title}
          </Typography>

          <Paper sx={styles.container}>
            <Box sx={styles.messagesBody}>
              {data?.threads?.map((thread: any, i: number) => {
                const isQuestion = data?.author?._id === thread?.author?._id;
                return isQuestion ? (
                  <Box key={i} sx={styles.message}>
                    <Avatar>{data?.author?.username}</Avatar>
                    <Box sx={styles.messageQuestion}>
                      <Typography variant="body1">{thread?.message}</Typography>
                      <Typography variant="body2" sx={styles.dateTime}>
                        {formatDateTime(thread.createdAt, 'YYYY-MM-DD HH:mm')}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box key={i} sx={styles.messageAnswer}>
                    <Typography variant="body1">{thread?.message}</Typography>
                    <Typography variant="body2" sx={styles.dateTime}>
                      {formatDateTime(thread.createdAt, 'YYYY-MM-DD HH:mm')}
                    </Typography>
                  </Box>
                );
              })}
              <div ref={messageBottomRef} />
            </Box>

            <Box sx={styles.input}>
              <InputText
                sx={styles.inputText}
                maxRows={3}
                multiline
                onChange={handleChangeMessage}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                <SendIcon />
              </Button>
            </Box>
          </Paper>
        </Box>
      )}
    </Page>
  );
};

export default QnaView;
