import React, { useRef, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IError, IPagination, ISortBy } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';
import AnimalView from './AnimalView';
import ImageList from './ImageListPopupContainer';
import UserView from './UserView';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: any;
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: unknown) => void;
}

interface ActionParams {
  command: string;
  params: unknown;
}

const AnimalList = ({
  loading,
  errors,
  list,
  sort,
  pagination,

  onAction,
}: Props) => {
  const actionArgs = useRef<ActionParams>();

  const [viewAnimal, setViewAnimal] = useState<any>(undefined);
  const [viewUser, setViewUser] = useState(undefined);
  const [viewImages, setViewImages] = useState<any>(undefined);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const headers = [
    {
      id: 'username',
      label: '회원 아이디',
      getValue: (obj: any) => {
        return (
          <Link onClick={() => setViewUser(obj?.ar?.owner)}>
            {obj.eventUser?.user?.nickName}
          </Link>
        );
      },
    },
    {
      id: 'animal.name',
      label: '반려동물 이름',
      getValue: (obj: any) => {
        return (
          <Link
            onClick={() =>
              setViewAnimal({
                animal: obj?.animal,
                mediaFile: obj?.mediaFile,
                inspection: obj?.inspection,
              })
            }
          >
            {obj?.animal?.name}
          </Link>
        );
      },
    },
    {
      id: 'animal.breed',
      label: t('Breed'),
      getValue: (obj: any) => obj?.animal?.breed?.name,
    },
    {
      id: 'countUpload',
      label: t('CountUpload'),
      getValue: (obj: any) => {
        return (
          <Link
            onClick={() => setViewImages({ animalId: obj?._id, status: 'ALL' })}
          >
            {obj?.count?.upload}
          </Link>
        );
      },
    },
    {
      id: 'countUpload',
      label: '검수 통과된 건수',
      getValue: (obj: any) => {
        return (
          <Link
            onClick={() =>
              setViewImages({ animalId: obj?._id, status: 'PASS' })
            }
          >
            {obj?.count?.pass}
          </Link>
        );
      },
    },

    {
      id: 'countUpload',
      label: '검수 거절된 건수',
      getValue: (obj: any) => {
        return (
          <Link
            onClick={() =>
              setViewImages({ animalId: obj?._id, status: 'REJECT' })
            }
          >
            {obj?.count?.reject}
          </Link>
        );
      },
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: any) => dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
    // {
    //   id: 'view',
    //   label: '열람',

    //   getValue: (obj: any) => {
    //     return (
    //       <IconButton onClick={() => setViewAnimal(obj?.animal)}>
    //         <ViewIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  const handleAction = (command: string, params: unknown) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('Animal')}
      pageTitle={
        <Typography variant="h4">
          {t('Animal')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: any) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: (actionArgs.current?.params as unknown[])?.length,
        })}
        onClose={handleConfirm}
      />
      <AnimalView
        open={!!viewAnimal}
        eventAnimal={viewAnimal}
        onClose={() => {
          setViewAnimal(undefined);
        }}
      />
      <ImageList
        open={!!viewImages}
        animalId={viewImages?.animalId}
        status={viewImages?.status}
        onClose={() => {
          setViewImages(undefined);
        }}
      />

      <UserView
        open={!!viewUser}
        eventUser={viewUser}
        onClose={() => {
          setViewUser(undefined);
        }}
      />
    </Page>
  );
};

export default AnimalList;
