import About from '../modules/about/About';
import AnimalList from '../modules/animal/ListContainer';
import Dashboard from '../modules/dashboard/DashboardContainer';
import ForgotPassword from '../modules/auth/ForgotPasswordContainer';
import Home from '../modules/home/Home';
import ResetPassword from '../modules/auth/ResetPasswordContainer';
import SignIn from '../modules/auth/SignInContainer';
import BreedList from '../modules/breed/ListContainer';
import DataEventAnimalInspect from '../modules/dataEvent/AnimalInspectContainer';
import DataEventAnimalList from '../modules/dataEvent/AnimalListContainer';
import DataEventImageInspection from '../modules/dataEvent/ImageInspectionContainer';
import DataEventImageList from '../modules/dataEvent/ImageListContainer';
import DataEventUserList from '../modules/dataEvent/UserListContainer';
import FaceIdEmbedList from '../modules/faceId/EmbedListContainer';
import FaceIdVerifyList from '../modules/faceId/VerifyListContainer';
import BreederList from '../modules/list/BreederList';
import Preference from '../modules/preference/Preference';
import QnaList from '../modules/qna/ListContainer';
import QnaView from '../modules/qna/ViewContainer';
import UserDeactivatedList from '../modules/user/DeactivatedListContainer';
import UserEdit from '../modules/user/EditContainer';
import UserLeavedList from '../modules/user/LeavedListContainer';
import UserList from '../modules/user/ListContainer';
import PetList from '../modules/list/PetList';
import Profile from '../modules/profile/Profile';
import RoleList from '../modules/role/ListContainer';
import StaffList from '../modules/staff/ListContainer';
import StaffNew from '../modules/staff/NewContainer';
import PetmilyUserList from '../modules/petmily/UserListContainer';
import PetmilyEventUserList from '../modules/petmily/EventUserListContainer';
import PetmilyEventAnimalList from '../modules/petmily/AnimalListContainer';

export const routes = [
  { path: '/forgot-password', exact: true, Component: ForgotPassword },
  { path: '/reset-password/:token', exact: true, Component: ResetPassword },
  { path: '/sign-in', exact: true, Component: SignIn },
];

export const privateRoutes = [
  { path: '/', exact: true, Component: Dashboard },
  { path: '/about', exact: true, Component: About },
  { path: '/animals', exact: true, Component: AnimalList },
  { path: '/breeds', exact: true, Component: BreedList },
  { path: '/breeders', exact: true, Component: BreederList },
  { path: '/data-event-animals', exact: true, Component: DataEventAnimalList },
  {
    path: '/data-event-animals/:id/inspect',
    exact: true,
    Component: DataEventAnimalInspect,
  },
  { path: '/data-event-images', exact: true, Component: DataEventImageList },
  {
    path: '/data-event-images-inspection',
    exact: true,
    Component: DataEventImageInspection,
  },
  { path: '/data-event-users', exact: true, Component: DataEventUserList },
  { path: '/faceid-embeddings', exact: true, Component: FaceIdEmbedList },
  { path: '/faceid-verifications', exact: true, Component: FaceIdVerifyList },
  { path: '/home', exact: true, Component: Home },
  { path: '/qnas', exact: true, Component: QnaList },
  { path: '/qnas/:id', exact: true, Component: QnaView },
  { path: '/users-deactivated', exact: true, Component: UserDeactivatedList },
  { path: '/users-leaved', exact: true, Component: UserLeavedList },
  { path: '/users', exact: true, Component: UserList },
  { path: '/users/:id/edit', exact: true, Component: UserEdit },
  { path: '/pets', exact: true, Component: PetList },
  { path: '/profile', exact: true, Component: Profile },
  { path: '/staves', exact: true, Component: StaffList },
  { path: '/staves/new', exact: true, Component: StaffNew },
  { path: '/preference', exact: true, Component: Preference },
  { path: '/roles', exact: true, Component: RoleList },
  { path: '/petmily/users', expect: true, Component: PetmilyUserList },
  {
    path: '/petmily/event-users',
    expect: true,
    Component: PetmilyEventUserList,
  },
  {
    path: '/petmily/event-animals',
    expect: true,
    Component: PetmilyEventAnimalList,
  },
];
