/**
 * 앱의 실행 초기에 서버로부터 초기화 과정에 필요한 정보를 읽어 하위 컴포넌트로 전달한다.
 */
import React from 'react';
import { useQuery } from '@apollo/client';

import { ME, ADMIN_SYSTEM_LIST } from '../graphql';
import { filterGraphQLErrors } from '../lib';
import Router from './Router';

/**
 * - 서버에 저장된 서버 설정 정보를 읽어온다.
 * - 지금 접속하는 계정 정보를 읽어온다.
 *
 * @returns
 */
const RouterContainer = () => {
  const { loading: loadingSystem, error: errorSystem } = useQuery(
    ADMIN_SYSTEM_LIST,
    {
      fetchPolicy: 'network-only',
    },
  );

  const {
    loading: loadingMe,
    error: errorMe,
    data: dataMe,
    refetch,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const handleSignOut = () => {
    refetch();
  };

  const errors = filterGraphQLErrors(errorSystem)?.concat(
    filterGraphQLErrors(errorMe) || [],
  );

  return (
    <Router
      loading={loadingSystem || loadingMe}
      me={dataMe?.me}
      errors={errors}
      onSignOut={handleSignOut}
    />
  );
};

export default RouterContainer;
