import { gql } from '@apollo/client';

export const ANIMAL_LIST = gql`
  query AdminAnimalList($params: ListParams) {
    adminAnimalList(params: $params) {
      _id
      totalCount
      list {
        _id
        name
        breed {
          _id
          name
        }
        gender
        birthday
        neuter
        color
        feature
        adoptionDay
        arn
        faceId {
          aiKey
        }
        owner {
          _id
          username
        }
        createdAt
      }
    }
  }
`;
