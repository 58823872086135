import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { filterGraphQLErrors } from '../../lib';
import { RESET_PASSWORD } from '../../graphql';
import ResetPassword from './ResetPassword';

interface IParams {
  token: string;
}

const ResetPasswordContainer = () => {
  const { token }: IParams = useParams();

  const history = useHistory();
  const [requestDone, setRequestDone] = useState<boolean>(false);

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: (response) => {
      if (!response?.adminResetPassword) return;

      setRequestDone(true);
    },
  });

  const handleSubmit = (password: string) => {
    resetPassword({ variables: { token, password } });
  };

  const handleAlert = () => {
    setRequestDone(false);
  };

  const handleGoHome = () => {
    history.push('/');
  };

  return (
    <ResetPassword
      submitting={loading}
      errors={filterGraphQLErrors(error)}
      requestDone={requestDone}
      onSubmit={handleSubmit}
      onAlertClose={handleAlert}
      onGoHome={handleGoHome}
    />
  );
};

export default ResetPasswordContainer;
