import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { IError, IUser } from 'face-id-types';

import { t, getDate } from '../../lib';
import { Page } from '../../layout';
import { Button, InputText, Loading } from '../../components';
import EditForm from './EditForm';

interface Props {
  loading: boolean;
  errors?: IError[];
  data?: any;
}

const styles = {
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  label: { width: 160, paddingRight: 1, textAlign: 'right' },
};

const Label = ({ text }: { text: string }) => (
  <Box sx={styles.label}>
    <Typography variant="subtitle1">{text}:</Typography>
  </Box>
);

const UserEdit = ({ loading, errors, data }: Props) => {
  const { _id, username, emails, status, general, createdAt } = data || {};

  const [disabled, setDisabled] = useState(true);

  const handleChangeStatus = (e: SelectChangeEvent) => {
    console.log('handleChangeStatus', e.target.value);
    setDisabled(false);
  };

  return (
    <Page
      title={t('User')}
      pageTitle={
        <Typography variant="h4">
          {t('User')} <small>{t('Update')}</small>
        </Typography>
      }
      headerRight={<Button disabled={disabled}>Save</Button>}
    >
      {loading ? (
        <Loading />
      ) : (
        <EditForm data={data} />
        // <Box>
        //   <Box sx={styles.input}>
        //     <Label text={t('ID')} />
        //     <Typography variant="subtitle1">{_id.toString()}</Typography>
        //   </Box>
        //   <Box sx={styles.input}>
        //     <Label text={t('Username')} />
        //     <Typography variant="subtitle1">{username}</Typography>
        //   </Box>
        //   <Box sx={styles.input}>
        //     <Label text={t('EmailAddress')} />
        //     <Typography variant="subtitle1">{emails?.[0]?.address}</Typography>
        //   </Box>
        //   <Box sx={styles.input}>
        //     <Label text={t('Status')} />
        //     <Select
        //       label=""
        //       value={status}
        //       variant="filled"
        //       fullWidth
        //       onChange={handleChangeStatus}
        //     >
        //       <MenuItem value="ACTIVE">{t(`USER_STATUS_ACTIVE`)}</MenuItem>
        //       <MenuItem value="HOLD">{t(`USER_STATUS_HOLD`)}</MenuItem>
        //       <MenuItem value="INACTIVATED">
        //         {t(`USER_STATUS_INACTIVATED`)}
        //       </MenuItem>
        //       <MenuItem value="LEAVING">{t(`USER_STATUS_LEAVING`)}</MenuItem>
        //     </Select>
        //   </Box>
        // </Box>
      )}
    </Page>
  );
};

export default UserEdit;
