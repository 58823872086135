import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material';

import { t } from '../../lib';
import { UIContext } from '../../context';
import { Page } from '../../layout';
import { LOCAL_STORAGE_KEY } from '../../config/constants';

const Preference = () => {
  const uiContext = useContext(UIContext);
  const [themeMode, setThemeMode] = useState('light');

  useEffect(() => {
    const theme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME_MODE);
    if (theme) {
      setThemeMode(theme);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTheme = (event.target as HTMLInputElement).value;
    if (newTheme === themeMode) return;

    setThemeMode(newTheme);
    localStorage.setItem(LOCAL_STORAGE_KEY.THEME_MODE, newTheme);
    uiContext.setValues({ theme: newTheme as 'light' | 'dark' | 'auto' });
  };

  return (
    <Page
      title={t('Settings')}
      pageTitle={<Typography variant="h4">{t('Settings')}</Typography>}
    >
      <List>
        <ListItem>
          <ListItemText primary={t('Theme')} />
          <FormControl>
            <RadioGroup row value={themeMode}>
              <FormControlLabel
                value="light"
                control={<Radio onChange={handleChange} />}
                label="Light"
              />
              <FormControlLabel
                value="dark"
                control={<Radio onChange={handleChange} />}
                label="Dark"
              />
              <FormControlLabel
                value="auto"
                control={<Radio onChange={handleChange} />}
                label="System"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
    </Page>
  );
};

export default Preference;
