import { gql } from '@apollo/client';

export const QNA_LIST = gql`
  query AdminQnaList($params: ListParams) {
    adminQnaList(params: $params) {
      _id
      totalCount
      list {
        _id
        title
        author {
          _id
          username
        }
        threads {
          message
          mediaFiles {
            _id
            version
            format
          }
          author {
            _id
            username
          }
          createdAt
        }
        closed
        countQuestion
        countAnswer
        createdAt
        updatedAt
      }
    }
  }
`;

export const QNA_VIEW = gql`
  query AdminQnaView($id: ID) {
    adminQnaView(id: $id) {
      _id
      author {
        _id
        username
      }
      title
      threads {
        message
        mediaFiles {
          _id
          version
          format
        }
        author {
          _id
          username
        }
        createdAt
      }
      closed
      countQuestion
      countAnswer
      createdAt
      updatedAt
    }
  }
`;

export const QNA_ANSWER = gql`
  mutation AdminQnaAnswer($input: QnaInput) {
    adminQnaAnswer(input: $input) {
      _id
      author {
        _id
        username
      }
      title
      threads {
        message
        mediaFiles {
          _id
          version
          format
        }
        author {
          _id
          username
        }
        createdAt
      }
      closed
      countQuestion
      countAnswer
      createdAt
      updatedAt
    }
  }
`;
