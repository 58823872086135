import React from 'react';
import { ListItem, Typography } from '@mui/material';

import { Button, Modal } from '../../components';
import { printJuminNumber, t } from '../../lib';

interface Props {
  open?: boolean;
  eventUser: any;
  onClose: (value?: any) => void;
}

const UserView = ({ open, eventUser, onClose }: Props) => {
  return (
    <Modal
      open={open}
      title={'보호자 상세'}
      Actions={<Button onClick={onClose}>{t('Okay')}</Button>}
      onClose={onClose}
    >
      <ListItem secondaryAction={<Typography>{eventUser?.name}</Typography>}>
        <Typography variant="body1">{t('Name')}</Typography>
      </ListItem>
      <ListItem
        secondaryAction={
          <Typography>
            {eventUser?.juminNumber && printJuminNumber(eventUser.juminNumber)}
          </Typography>
        }
      >
        <Typography variant="body1">{t('JuminNumber')}</Typography>
      </ListItem>
      <ListItem
        secondaryAction={<Typography>{eventUser?.phone?.number}</Typography>}
      >
        <Typography variant="body1">{t('Phone')}</Typography>
      </ListItem>
      <ListItem
        secondaryAction={<Typography>{eventUser?.address?.road}</Typography>}
      >
        <Typography variant="body1">{t('Address')}</Typography>
      </ListItem>
    </Modal>
  );
};

export default UserView;
