import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { IListParams, IPagination, ISortBy } from 'face-id-types';

import { PAGINATION_DEFAULT } from '../../config/constants';
import { filterGraphQLErrors, updateSort } from '../../lib';
import { BREED_LIST } from '../../graphql';
import BreedList from './List';

const BreedListContainer = () => {
  const history = useHistory();

  const [listParams, setListParams] = useState<IListParams>({
    skip: PAGINATION_DEFAULT.page * PAGINATION_DEFAULT.rowsPerPage,
    limit: PAGINATION_DEFAULT.rowsPerPage,
    sortBy: [{ field: 'name', order: 'ASC' }],
  });

  const { loading, error, data } = useQuery(BREED_LIST, {
    fetchPolicy: 'network-only',
    variables: { params: listParams },
  });

  const handleAction = (command: string, params?: unknown) => {
    switch (command) {
      case 'EDIT':
        history.push(`/breeds/${params as string}/edit`);
        break;

      case 'DELETE':
        break;

      case 'PAGE':
        setListParams({
          ...listParams,
          skip: (params as IPagination).page * listParams.limit,
        });
        break;

      case 'ROWS_PER_PAGE':
        setListParams({
          ...listParams,
          skip: 0,
          limit: (params as IPagination).rowsPerPage,
        });
        break;

      case 'SORT':
        setListParams({
          ...listParams,
          skip: 0,
          sortBy: updateSort(listParams.sortBy, params as ISortBy),
        });
        break;

      default:
    }
  };

  return (
    <BreedList
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminBreedList?.list || []}
      sort={listParams.sortBy}
      pagination={{
        count: data?.adminBreedList?.totalCount || 0,
        page: Math.floor(listParams.skip / listParams.limit),
        rowsPerPage: listParams.limit,
        rowsPerPageOptions: PAGINATION_DEFAULT.rowsPerPageOptions,
      }}
      onAction={handleAction}
    />
  );
};

export default BreedListContainer;
