import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getYear: number = new Date().getFullYear();

export const formatDateTime = (
  dateTime: Date,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  return dayjs(dateTime).format(format);
};

/**
 * 생일은 입력한 위치, 시점을 그대로 반영해야 하므로 local 시간을
 * 계산하여 리턴한다.
 *
 * @param {*} value
 * @param {*} format
 */
export const getDate = (value: Date, format: string) => {
  return dayjs.utc(value).local().format(format);
};

/**
 * 나이를 계산한다.
 * 현재 연도에서 생년월일의 연도를 뺀다.
 *
 * @param value 생년월일
 * @returns
 */
export const getAge = (value: Date): number => {
  const myYear: number = parseInt(dayjs.utc(value).local().format('YYYY'), 10);
  const age = getYear - myYear;
  return age;
};
