import React, { useState } from 'react';
import { Button, Tabs, Tab, Typography } from '@mui/material';

import { Page } from '../../layout';
import { t } from '../../lib';
import { TabPanel } from '../../components';
import ChangePassword from './ChangePasswordContainer';

const Profile = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Page
      title={t('Profile')}
      pageTitle={
        <Typography variant="h4">
          {t('Profile')} <small>{t('Details')}</small>
        </Typography>
      }
    >
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label={t('General')} />
        <Tab label={t('ChangePassword')} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Typography>General</Typography>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ChangePassword />
      </TabPanel>
    </Page>
  );
};

export default Profile;
