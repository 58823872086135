import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { SessionContext } from '../../context';
import { filterGraphQLErrors } from '../../lib';
import { SIGN_IN } from '../../graphql';
import SignIn from './SignIn';

interface LocationState {
  from: { pathname: string };
}

const SignInContainer = () => {
  const location = useLocation<LocationState>();
  const sessionContext = useContext(SessionContext);
  const history = useHistory();

  const { from } = location.state;

  const [signIn, { loading, error }] = useMutation(SIGN_IN, {
    onCompleted: (response) => {
      if (!response?.adminSignIn) return;

      sessionContext.signIn && sessionContext.signIn(response.adminSignIn);

      history.replace(
        !from?.pathname || from.pathname === '/sign-in'
          ? { pathname: '/' }
          : from,
      );
    },
  });

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleSubmit = (id: string, secret: string) => {
    signIn({ variables: { input: { channel: 'EMAIL', id, secret } } });
  };

  return (
    <SignIn
      submitting={loading}
      errors={filterGraphQLErrors(error)}
      onForgotPassword={handleForgotPassword}
      onSubmit={handleSubmit}
    />
  );
};

export default SignInContainer;
