import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  IDictionary,
  IError,
  IEventAnimal,
  InspectionStatusType,
} from 'face-id-types';

import { t, getImageURL } from '../../lib';
import { Page } from '../../layout';
import { Button, InputText, Loading } from '../../components';

interface Props {
  loading: boolean;
  errors?: IError[];
  eventAnimal?: IEventAnimal;
  rejectMessages?: IDictionary[];
  onAction?: (command: string, params?: unknown) => void;
}

const styles = {
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  label: { width: 160, paddingRight: 1, textAlign: 'right' },
};

const Label = ({ text }: { text: string }) => (
  <Box sx={styles.label}>
    <Typography variant="subtitle1">{text}:</Typography>
  </Box>
);

const AnimalInspect = ({
  loading,
  errors,
  eventAnimal,
  rejectMessages,
  onAction,
}: Props) => {
  const { animal, arCard, inspection } = eventAnimal || {};

  const [status, setStatus] = useState<InspectionStatusType>(
    inspection?.status || 'READY',
  );
  const [rejectMessage, setRejectMessage] = useState<string>(
    inspection?.rejectMessage || '',
  );

  const disabled = !status || (status === 'REJECT' && !rejectMessage);

  useEffect(() => {
    if (status !== eventAnimal?.inspection?.status) {
      setStatus(eventAnimal?.inspection?.status || 'READY');
    }
    if (rejectMessage !== eventAnimal?.inspection?.rejectMessage) {
      setRejectMessage(eventAnimal?.inspection?.rejectMessage || '');
    }
  }, [eventAnimal]);

  return (
    <Page
      title={t('Animal')}
      pageTitle={
        <Typography variant="h4">
          {t('Animal')} <small>{t('Inspection')}</small>
        </Typography>
      }
      headerRight={
        <Box>
          <Button
            onClick={() => {
              onAction('BACK');
            }}
          >
            {t('List')}
          </Button>
          <Button
            variant="contained"
            disabled={disabled}
            sx={{ marginLeft: 2 }}
            onClick={() => {
              onAction('SUBMIT', { status, rejectMessage });
            }}
          >
            {t('Save')}
          </Button>
        </Box>
      }
    >
      {loading || !rejectMessages ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          {errors && errors.length > 0 && (
            <Grid xs={12}>
              <Typography>{errors.map((doc) => t(doc.message))}</Typography>
            </Grid>
          )}
          <Grid xs={6}>
            <img
              src={getImageURL(arCard, 'default')}
              alt="animal"
              width={'100%'}
            />
          </Grid>
          <Grid xs={6}>
            <Box sx={styles.input}>
              <Label text={t('Name')} />
              <InputText
                name="name"
                value={animal.name}
                hiddenLabel
                fullWidth
              />
            </Box>
            <Box sx={styles.input}>
              <Label text={t('Inspection')} />
              <Select
                label=""
                value={status}
                variant="filled"
                fullWidth
                onChange={(e) =>
                  setStatus(e.target.value as InspectionStatusType)
                }
              >
                <MenuItem value="READY">
                  {t('INSPECTION_STATUS_READY')}
                </MenuItem>
                <MenuItem value="PASS">{t('INSPECTION_STATUS_PASS')}</MenuItem>
                <MenuItem value="REJECT">
                  {t('INSPECTION_STATUS_REJECT')}
                </MenuItem>
              </Select>
            </Box>
            {status === 'REJECT' &&
              rejectMessages &&
              rejectMessages?.length > 0 && (
                <Box sx={styles.input}>
                  <Label text={t('RejectMessage')} />
                  <Select
                    label=""
                    value={rejectMessage}
                    variant="filled"
                    fullWidth
                    onChange={(e) =>
                      setRejectMessage(e.target.value as InspectionStatusType)
                    }
                  >
                    <MenuItem value="">{'Select Reject Message'}</MenuItem>
                    {rejectMessages.map((item) => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default AnimalInspect;
