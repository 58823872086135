import React from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps,
} from '@mui/lab';

import { BUTTON_VARIANT } from '../config/styles';

interface Props extends ButtonProps {
  children: React.ReactNode;
}

interface LoadingProps extends LoadingButtonProps {
  children: React.ReactNode;
}

export const Button = ({ children, ...props }: Props) => {
  const buttonProps = { ...props, variant: props.variant || BUTTON_VARIANT };

  return <MuiButton {...buttonProps}>{children}</MuiButton>;
};

export const LoadingButton = ({ children, ...props }: LoadingProps) => {
  const buttonProps = { ...props, variant: props.variant || BUTTON_VARIANT };

  return <MuiLoadingButton {...buttonProps}>{children}</MuiLoadingButton>;
};
