import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IError } from 'face-id-types';

import { Button, InputPassword } from '../../components';
import { t } from '../../lib';

interface Props {
  submitting: boolean;
  errors?: IError[];
  onSubmit: (password: string, newPassword: string) => void;
}

const styles = {
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  label: { width: 160, paddingRight: 1, textAlign: 'right' },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 2,
  },
};

const Label = ({ text }: { text: string }) => (
  <Box sx={styles.label}>
    <Typography variant="subtitle1">{text}:</Typography>
  </Box>
);

const ChangePassword = ({ submitting, errors, onSubmit }: Props) => {
  const [password, setPassword] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string | undefined>();

  const handleSubmit = () => {
    onSubmit(password, newPassword);
  };

  return (
    <Box>
      {errors && errors.length > 0 && (
        <Typography>{errors.map((doc) => t(doc.message))}</Typography>
      )}
      <Box sx={styles.input}>
        <Label text={t('CurrentPassword')} />
        <InputPassword
          name="password"
          value={password}
          fullWidth
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Box>
      <Box sx={styles.input}>
        <Label text={t('NewPassword')} />
        <InputPassword
          name="newPassword"
          value={newPassword}
          fullWidth
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
      </Box>
      <Box sx={styles.actions}>
        <Button onClick={handleSubmit}>{t('Submit')}</Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
