import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  FetchResult,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils';
import { Icon, IconButton, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarKey, SnackbarProvider } from 'notistack';

import { LOCAL_STORAGE_KEY, SERVER } from '../config/constants';
import { NOTI_STACK_DEPTH, NOTI_STACK_ORIGIN } from '../config/styles';
import { isOfType, offsetLimitPagination, refreshTokenHandler } from '../lib';
import { getTheme } from '../config/theme';
import { UIContext, UIContextProvider } from '../context';
import Router from './RouterContainer';

/**
 * 서버에 접속한다.
 */
const httpLink = createHttpLink({
  uri: `${SERVER.url}/graphql`,
});

/**
 * 서버에 접속할 때, AccessToken을 header에 담아서 전달한다.
 */
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

/**
 * 서버에 접속할 때, AccessToken이 expire되었으면,
 * RefreshToken으로 AccessToken을 갱신 요청하고, 새로운 AccessToken으로 재요청한다.
 */
const resetLink = onError(
  ({
    networkError,
    operation,
    forward,
  }: ErrorResponse): Observable<FetchResult> | void => {
    if (networkError && isOfType<ServerError>(networkError, 'result')) {
      const message =
        networkError.result.errors && networkError.result.errors[0].message;

      if (
        message === 'Context creation failed: Token expired' ||
        message === 'Context creation failed: invalid signature'
      ) {
        return refreshTokenHandler({ operation, forward });
      } else {
        console.log('resetLink unknown error', message);
      }
    }
  },
);

/**
 * Apollo Client을 생성하고, 이를 이용하여 GraphQL 방식으로 서버와 통신한다.
 */
const client = new ApolloClient({
  link: resetLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          adminUserList: offsetLimitPagination(),
          adminUserDeactivatedList: offsetLimitPagination(),
          adminUserLeavedList: offsetLimitPagination(),
          adminAnimalList: offsetLimitPagination(),
          adminBreedList: offsetLimitPagination(),
          adminConsultingList: offsetLimitPagination(),
          adminEventUserList: offsetLimitPagination(),
          adminEventAnimalList: offsetLimitPagination(),
          adminEventImageList: offsetLimitPagination(),
          adminEventImageInspectionList: offsetLimitPagination(),
          adminRoleList: offsetLimitPagination(),
          adminStaffList: offsetLimitPagination(),
        },
      },
    },
  }),
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
  },
});

/**
 * App 컴포넌트를 리턴한다. 다음 기능을 Context 방식으로 구현한다.
 * - Apollo GraphQL
 * - Theme
 * - 화면 Layout
 *
 * @returns
 */
const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const notistackRef = React.createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <ApolloProvider client={client}>
      <UIContextProvider>
        <UIContext.Consumer>
          {({ theme }) => (
            <ThemeProvider theme={getTheme(theme, prefersDarkMode)}>
              <SnackbarProvider
                ref={notistackRef}
                maxSnack={NOTI_STACK_DEPTH}
                anchorOrigin={NOTI_STACK_ORIGIN}
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)}>
                    <Icon>close</Icon>
                  </IconButton>
                )}
              >
                <HelmetProvider>
                  <CssBaseline />
                  <Router />
                </HelmetProvider>
              </SnackbarProvider>
            </ThemeProvider>
          )}
        </UIContext.Consumer>
      </UIContextProvider>
    </ApolloProvider>
  );
};

export default App;
