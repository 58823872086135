import React from 'react';
import { useQuery } from '@apollo/client';

import { STAT_TODAY } from '../../graphql';
import Dashboard from './Dashboard';
import { filterGraphQLErrors } from '../../lib';

const DashboardContainer = () => {
  const { loading, error, data, refetch } = useQuery(STAT_TODAY);

  return (
    <Dashboard
      loading={loading}
      errors={filterGraphQLErrors(error)}
      data={data?.adminStatToday}
      onRefetch={() => {
        refetch();
      }}
    />
  );
};

export default DashboardContainer;
