const errors = {
  error_active_role_remove_reject_message:
    '권한이 부여된 회원이 존재하는 권한은 삭제할 수 없습니다.',
  ERROR_AUTHORIZATION: '접근 권한이 없습니다. 관리자에게 문의하세요.',
  error_duplicate_key: '이미 등록된 데이터가 있습니다.',
  error_input_required: '입력값이 누락되었습니다.',
  error_insecure_password:
    '안전하지 않은 암호 문자열입니다. 영문,숫자,특수기호,대소문자를 혼용하여 최소 6자 이상의 문자열로 작성하세요.',
  error_invalid_email: '올바른 이메일주소 형식이 아닙니다.',
  error_invalid_token:
    '문자열 토큰이 유효하지 않습니다. 확인 후, 다시 시도하세요.',
  ERROR_PASSWORD_NOT_MATCH: '비밀번호가 일치하지 않습니다',
  error_sign_in: '존재하지 않는 계정이거나 비밀번호가 일치하지 않습니다.',
  error_sign_in_status: '이용이 중지된 계정입니다.',
  error_length_range:
    '문자열 길이는 %{minLength}자 이상 %{maxLength}자 이하 범위에 있어야 합니다.',
};

export default errors;
