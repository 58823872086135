import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

interface Props {
  open: boolean;
  title?: string;
  children?: ReactNode;
  Actions?: ReactNode;
  onClose: (value?: any) => void;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const Modal = ({
  open = true,
  title,
  children,
  Actions,
  onClose,
  maxWidth,
}: Props) => {
  return (
    <Dialog open={open} maxWidth={maxWidth || 'sm'} fullWidth onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {Actions && <DialogActions>{Actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
