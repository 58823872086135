import React, { useRef, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  IError,
  IEventImage,
  IEventUser,
  IPagination,
  ISortBy,
} from 'face-id-types';

import { DataTable } from '../../components';
import { findSortOrder, getImageURL, t } from '../../lib';
import { Page } from '../../layout';
import Inspect from './InspectContainer';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IEventUser[];
  sort?: ISortBy[];
  status?: string;
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
  onChangeStatus?: (status: string | undefined) => void;
}

const ImageList = ({
  loading,
  errors,
  list,
  sort,
  status,
  pagination,
  onAction,
  onChangeStatus,
}: Props) => {
  const actionArgs = useRef<any>();

  const [inspectionOpen, setInspectionOpen] = useState<
    IEventImage | undefined
  >();

  const headers = [
    {
      id: 'animalName',
      label: t('Name'),
      sort: findSortOrder(sort, 'animal.name'),
      getValue: (obj: IEventImage) => obj.animal.name,
    },
    {
      id: 'image',
      label: t('EventData'),
      sort: findSortOrder(sort, 'mediaFile._id'),
      getValue: (obj: IEventImage) => (
        <img
          src={getImageURL(obj.mediaFile, 'thumbnail')}
          style={{ height: 80 }}
        />
      ),
    },
    {
      id: 'duplicates',
      label: t('Duplicates'),
      getValue: (obj: IEventImage) => obj.duplicates?.length || 0,
    },
    {
      id: 'status',
      label: t('Status'),
      // sort: findSortOrder(sort, 'status'),
      getValue: (obj: IEventImage) => (
        <Button
          onClick={() => {
            setInspectionOpen(obj);
          }}
          autoFocus
        >
          {t(`INSPECTION_STATUS_${obj?.inspection?.status}`)}
        </Button>
      ),
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IEventImage) =>
        dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    // setInspectionOpen(true);
    // if (command === 'DELETE') {
    //   actionArgs.current = { command, params };
    //   setInspectionOpen(true);
    // } else {
    //   onAction(command, params);
    // }
  };

  const handleCloseInspect = (updated: boolean) => {
    setInspectionOpen(undefined);
    if (updated) {
      onAction('REFRESH');
    }
  };

  return (
    <Page
      title={t('Inspection')}
      pageTitle={
        <Typography variant="h4">
          {t('Inspection')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <Grid>
        <ToggleButtonGroup
          color="primary"
          value={status}
          exclusive
          onChange={(e, value) => {
            onChangeStatus(value);
          }}
        >
          {/* <ToggleButton value="">전체</ToggleButton> */}
          <ToggleButton value="READY">대기</ToggleButton>
          <ToggleButton value="REJECT">반려</ToggleButton>
          <ToggleButton value="PASS">통과</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IEventUser) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        noSelect
        onAction={handleAction}
      />
      <Inspect image={inspectionOpen} onClose={handleCloseInspect} />
    </Page>
  );
};

export default ImageList;
