import { MenuDummy } from '../layout/Menu';
import { t } from '../lib';

const menu: MenuDummy[] = [
  {
    type: 'list',
    list: [
      {
        title: t('Home'),
        path: '/home',
        icon: 'home',
      },
    ],
    roles: ['ROLE_PARTNER'],
  },
  {
    type: 'list',
    list: [
      {
        title: t('Dashboard'),
        path: '/',
        icon: 'dashboard',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: t('User'),
        children: [
          {
            title: t('User'),
            path: '/users',
            icon: 'people',
          },
          {
            title: t('UserDeactivated'),
            path: '/users-deactivated',
            icon: 'people_outline',
          },
          {
            title: t('UserLeaved'),
            path: '/users-leaved',
            icon: 'person_off',
          },
        ],
        icon: 'folder',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: t('Animal'),
        children: [
          {
            title: t('Animal'),
            path: '/animals',
            icon: 'pets',
          },
          {
            title: t('Breed'),
            path: '/breeds',
            icon: 'interests',
          },
        ],
        icon: 'folder',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: t('CS'),
        children: [
          {
            title: t('Qna'),
            path: '/qnas',
            icon: 'event',
          },
        ],
        icon: 'folder',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  // {
  //   type: 'list',
  //   list: [
  //     {
  //       title: t('Consulting'),
  //       children: [
  //         {
  //           title: t('ConsultingRequest'),
  //           path: '/consultings',
  //           icon: 'event',
  //         },
  //       ],
  //       icon: 'folder',
  //     },
  //   ],
  //   roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  // },
  // {
  //   type: 'divider',
  // },
  {
    type: 'list',
    list: [
      {
        title: t('AiData'),
        children: [
          {
            title: t('EventUser'),
            path: '/data-event-users',
            icon: 'people',
          },
          {
            title: t('Animal'),
            path: '/data-event-animals',
            icon: 'pets',
          },
          {
            title: t('Register'),
            path: '/faceid-embeddings',
            icon: 'face',
          },
          {
            title: t('Verification'),
            path: '/faceid-verifications',
            icon: 'face',
          },
          // {
          //   title: t('EventData'),
          //   path: '/data-event-images',
          //   icon: 'photo',
          // },
          // {
          //   title: t('Inspection'),
          //   path: '/data-event-images-inspection',
          //   icon: 'thumbs_up_down',
          // },
        ],
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: 'Petmily',
        children: [
          {
            title: t('User'),
            path: '/petmily/users',
            icon: 'people',
          },
          // {
          //   title: t('EventUser'),
          //   path: '/petmily/event-users',
          //   icon: 'people',
          // },
          {
            title: t('Animal'),
            path: '/petmily/event-animals',
            icon: 'pets',
          },
        ],
        icon: 'folder',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: t('System'),
        children: [
          {
            title: t('Role'),
            path: '/roles',
            icon: 'lock',
          },
          {
            title: t('Account'),
            path: '/staves',
            icon: 'account_circle',
          },
        ],
        icon: 'folder',
      },
    ],
    roles: ['ROLE_ROOT'],
  },
  {
    type: 'divider',
  },
  {
    type: 'list',
    list: [
      {
        title: t('Settings'),
        path: '/preference',
        icon: 'settings',
      },
      {
        title: t('About'),
        path: '/about',
        icon: 'info',
      },
    ],
    roles: ['ROLE_ROOT', 'ROLE_STAFF', 'ROLE_PARTNER'],
  },
];

export default menu;
