import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { IError, IUser } from 'face-id-types';

import { t, getDate } from '../../lib';
import { Page } from '../../layout';
import { Button, InputText, InputUsername, Loading } from '../../components';

interface Props {
  loading: boolean;
  errors?: IError[];
  onSubmit: (data: any) => void;
}

const styles = {
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  label: { width: 160, paddingRight: 1, textAlign: 'right' },
};

const Label = ({ text }: { text: string }) => (
  <Box sx={styles.label}>
    <Typography variant="subtitle1">{text}:</Typography>
  </Box>
);

const StaffNew = ({ loading, errors, onSubmit }: Props) => {
  const [username, setUsername] = useState<
    { value: string; valid: boolean } | { value: ''; valid: false }
  >();
  const [email, setEmail] = useState<string | undefined>();

  const disabled = !(username?.valid && email);

  const handleSubmit = () => {
    if (!disabled) {
      onSubmit({ username: username?.value, email });
    }
  };

  return (
    <Page
      title={t('Account')}
      pageTitle={
        <Typography variant="h4">
          {t('Account')} <small>{t('Register')}</small>
        </Typography>
      }
      headerRight={
        <Button disabled={disabled} onClick={handleSubmit}>
          {t('Register')}
        </Button>
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Box sx={styles.input}>
            <Label text={t('Username')} />
            <InputUsername
              name="username"
              value={username?.value}
              fullWidth
              onChange={(value: string, valid: boolean) => {
                setUsername({ value, valid });
              }}
            />
          </Box>
          <Box sx={styles.input}>
            <Label text={t('EmailAddress')} />
            <InputText
              name="email"
              value={email}
              hiddenLabel
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
        </Box>
      )}
    </Page>
  );
};

export default StaffNew;
