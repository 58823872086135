/**
 * 관리시스템 접근 계정의 비밀번호 찾기 기능을 구현한다.
 *
 */

import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IError } from 'face-id-types';

import { Page } from '../../layout';
import { t } from '../../lib';
import { InputText, LoadingButton } from '../../components';

interface Props {
  submitting: boolean;
  errors?: IError[];
  onForgotPassword: () => void;
  onSubmit: (email: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  forgotPassword: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
}));

const ForgotPassword = ({
  submitting,
  errors,
  onForgotPassword,
  onSubmit,
}: Props) => {
  const classes = useStyles();

  const [errorMessages, setErrorMessages] = useState<IError[] | undefined>();
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessages(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (submitting) return;

    onSubmit(email);
  };

  return (
    <Page layout="BOX" title={t('ForgotPassword')}>
      <form>
        <Grid container spacing={2}>
          {errorMessages && errorMessages.length > 0 && (
            <Grid item xs={12}>
              <Typography>
                {errorMessages.map((doc) => t(doc.message))}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputText
              name="email"
              value={email}
              label={t('EmailAddress')}
              fullWidth
              autoComplete="username"
              onChange={handleChangeEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={submitting}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              {t('Submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Page>
  );
};

export default ForgotPassword;
