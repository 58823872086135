import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { t } from '../lib';

interface Props {
  open: boolean;
  title?: string;
  message?: string;
  onClose: () => void;
}

const Alert = ({ open, title, message, onClose }: Props) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t('Okay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Alert;
