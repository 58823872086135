import React, { useRef, useState } from 'react';
import { Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IError, IEventUser, IPagination, ISortBy } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';
import UserView from './UserView';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IEventUser[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
}

const UserList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
}: Props) => {
  const actionArgs = useRef<any>();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [viewUser, setViewUser] = useState<IEventUser | undefined>(undefined);

  const headers = [
    {
      id: 'username',
      label: t('Username'),
      sort: findSortOrder(sort, 'user.username'),
      getValue: (obj: IEventUser) => obj.user.username,
    },
    {
      id: 'phoneAuth.name',
      label: t('Name'),
      sort: findSortOrder(sort, 'phoneAuth.name'),
      getValue: (obj: IEventUser) => {
        return (
          <Link
            component="button"
            onClick={() => {
              setViewUser(obj);
            }}
          >
            {obj.phoneAuth.name}
          </Link>
        );
      },
    },
    {
      id: 'juminNumber',
      label: t('JuminNumber'),
      getValue: (obj: IEventUser) =>
        obj?.juminNumber
          ? `${obj?.juminNumber?.substring(0, 6)}-${obj?.juminNumber?.substring(
              6,
              7,
            )}******`
          : '',
    },
    {
      id: 'address',
      label: t('Address'),
      getValue: (obj: IEventUser) =>
        obj?.address ? `${obj?.address?.province} ${obj?.address?.city}` : '',
    },
    // {
    //   id: 'status',
    //   label: t('Status'),
    //   sort: findSortOrder(sort, 'status'),
    //   getValue: (obj: IUser) => t(`USER_STATUS_${obj.status}`),
    // },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IEventUser) =>
        dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('EventUser')}
      pageTitle={
        <Typography variant="h4">
          {t('EventUser')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IEventUser) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: actionArgs.current?.params?.length,
        })}
        onClose={handleConfirm}
      />
      <UserView
        open={!!viewUser}
        eventUser={viewUser}
        onClose={() => {
          setViewUser(undefined);
        }}
      />
    </Page>
  );
};

export default UserList;
