import { IMediaFile } from 'face-id-types';

import { CLOUDINARY_URL_PREFIX } from '../config/constants';

interface IImageTransform {
  default: string;
  compact: string;
  thumbnail: string;
}

const IMAGE_TRANSFORMS: IImageTransform = {
  default: 'c_scale,w_1080',
  compact: 'c_scale,w_320',
  thumbnail: 'c_scale,w_150',
};

/**
 * Cloudinary.com 클라우드의 이미지 URL을 크기에 따라 다르게 리턴한다.
 *
 * @param {*} image
 * @param {*} size
 */

export const getImageURL = (
  mediaFile: IMediaFile | undefined,
  size = 'default',
) => {
  let xform = IMAGE_TRANSFORMS.default;
  if (size === 'compact') {
    xform = IMAGE_TRANSFORMS.compact;
  } else if (size === 'thumbnail') {
    xform = IMAGE_TRANSFORMS.thumbnail;
  }
  const url = `${CLOUDINARY_URL_PREFIX}/image/upload/${xform}/v${mediaFile?.version}/${mediaFile?._id}.${mediaFile?.format}`;

  return url;
};

export const getPetmilyImageURL = (
  mediaFile: IMediaFile | undefined,
  size = 'default',
) => {
  let xform = IMAGE_TRANSFORMS.default;
  if (size === 'compact') {
    xform = IMAGE_TRANSFORMS.compact;
  } else if (size === 'thumbnail') {
    xform = IMAGE_TRANSFORMS.thumbnail;
  }
  const url = `https://res.cloudinary.com/petmily/image/upload/${xform}/v${mediaFile?.version}/${mediaFile?._id}.${mediaFile?.format}`;

  return url;
};

export const getVideoURL = (mediaFile: IMediaFile | undefined) => {
  const url = `${CLOUDINARY_URL_PREFIX}/video/upload/q_auto:good/v${mediaFile.version}/${mediaFile._id}.${mediaFile.format}`;

  return url;
};
