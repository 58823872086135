import { gql } from '@apollo/client';

export const USER_DEACTIVATED_LIST = gql`
  query AdminUserDeactivatedList($params: ListParams) {
    adminUserDeactivatedList(params: $params) {
      _id
      totalCount
      list {
        _id
        username
        emails {
          address
          verified
        }
        auths {
          channel
          id
        }
        signInChannel
        roles
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_LEAVED_LIST = gql`
  query AdminUserLeavedList($params: ListParams) {
    adminUserLeavedList(params: $params) {
      _id
      totalCount
      list {
        _id
        username
        emails {
          address
          verified
        }
        auths {
          channel
          id
        }
        roles
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_LIST = gql`
  query AdminUserList($params: ListParams) {
    adminUserList(params: $params) {
      _id
      totalCount
      list {
        _id
        username
        emails {
          address
          verified
        }
        auths {
          channel
          id
        }
        signInChannel
        roles
        status
        general {
          firstName
          lastName
          name
          phone
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_VIEW = gql`
  query AdminUserView($id: ID) {
    adminUserView(id: $id) {
      _id
      username
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
        name
        phone
      }
      roles
      createdAt
      updatedAt
    }
  }
`;

export const USER_NEW = gql`
  mutation UserNew($input: UserInput) {
    userNew(input: $input) {
      _id
      emails {
        address
        verified
      }
      general {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UserUpdate($id: ID, $input: UserUpdateInput) {
    userUpdate(id: $id, input: $input) {
      _id
      emails {
        address
        verified
      }
      general {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_REMOVE = gql`
  mutation UserRemove($id: ID) {
    userRemove(id: $id)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: String, $newPassword: String) {
    changePassword(password: $password, newPassword: $newPassword)
  }
`;
