const messages = {
  About: '앱 정보',
  Account: '계정',
  Actions: '작업',
  Add: '추가',
  AdditionalMessage: '추가 메시지',
  Address: '주소',
  AddressEmptyMessage: '등록된 주소 없음',
  Adoptionday: '입양 날짜',
  Ai: 'AI',
  AiData: 'AI 데이터',
  AiEvent: 'AI 행사',
  AiEventName: '행사명',
  Animal: '반려동물',
  Answer: '답변',
  AppInfo: '앱 정보',
  AppName: '관리시스템',
  Ar: '동물등록정보',
  ArCard: '동물등록증',
  Arn: '동물등록번호',
  ArOrg: '등록기관',
  Author: '작성자',
  Bank: '은행정보',
  BankAccount: '계좌번호',
  BankHolder: '예금주',
  BankName: '은행명',
  Board: '게시판',
  Bookmark: '북마크',
  Breed: '품종',
  Birthday: '생년월일',
  Cancel: '취소',
  CardName: '카드명',
  CardNumber: '카드번호',
  CAT: '고양이',
  Category: '카테고리',
  CategoryEditDoneMessage: '카테고리 정보가 변경되었습니다.',
  CategoryNewDoneMessage: '카테고리가 등록되었습니다.',
  ChangePassword: '비밀번호 변경',
  CheckDuplicates: '중복 확인',
  Claim: '신고',
  ClaimedAt: '신고일시',
  ClaimComment: '신고된 댓글',
  ClaimMessage: '신고 내용',
  ClaimPost: '신고된 게시물',
  ClaimUser: '신고한 회원',
  Close: '닫기',
  Clothing: '의류',
  ClothingCategory: '옷종류별',
  ClothingsByUser: '회원별 의류 목록',
  ClothingsByCategory: '카테고리별 의류 목록',
  Comment: '댓글',
  Company: '업체',
  Color: '털색상',
  Consulting: '상담',
  ConsultingRequest: '상담신청',
  Count: '수량',
  CountDays: '참여 일수',
  CountPass: '인증 건수',
  CountQuestion: '문의건수',
  CountReject: '반려건수',
  CountTotal: '총 %{total}건',
  CountTotalAndSelected: '총 %{total}건 중 %{selected}건 선택됨',
  CountUpload: '등록 건수',
  CouponSentAt: '쿠폰 발송일',
  CouponSentMessage: '쿠폰이 "%{name}"에게 발송되었습니다.',
  CreatedAt: '등록 일시',
  CS: '고객센터',
  CurrentPassword: '현재 비밀번호',
  Dashboard: '대시보드',
  DashboardRealTime: '실시간 (오늘)',
  DashboardTotal: '누적 (어제)',
  Data: '데이터',
  Date: '일시',
  DeleteConfirmMessage: '선택한 %{count}건을 삭제하시겠습니까?',
  Delivery: '배송',
  DeliveryCode: '송장번호',
  DeliveryCodeDoneMessage: '송장번호가 등록되었습니다.',
  DeliveryCompany: '배송업체',
  DeliveryRequest: '배송요청사항',
  DeliveryUrl: '배송조회URL',
  Description: '설명',
  Details: '상세',
  DeliveryFee: '배송비',
  Display: '표시',
  DOG: '강아지',
  Duplicates: '중복',
  EditName: '이름 수정',
  EngName: '영문명',
  EmailAddress: '이메일주소',
  EmptyListMessage: '데이터가 없습니다.',
  EventData: '업로드 데이터(old)',
  EventUser: '참가 회원',
  FaceId: '페이스ID',
  Faq: 'FAQ',
  Feature: '특징',
  Feedback: '피드백',
  FeeThreshold: '배송정책',
  Female: '암컷',

  Finished: '종료',
  FirstName: '이름',
  ForgotPassword: '비밀번호를 잊으셨나요?',
  ForgotPasswordDoneMessage: '비밀번호 초기화 안내 메일이 발송되었습니다.',
  Gender: '성별',
  General: '일반',
  Group: '분류',
  GuideUrl: '촬영가이드URL',
  Hidden: '숨김',
  HiddenDoneMessage: '숨김 상태를 변경했습니다.',
  Home: '홈',
  HomeUrl: '안내화면URL',
  ID: 'ID',
  ImageDetected: '검출',
  ImageOriginal: '원본',
  ImageSize: '이미지 크기',
  ImageList: '이미지 목록',
  Inspector: '검수자',
  Inspection: '검수(old)',
  InspectionReady: '검수 대기',
  InspectionPass: '검수 통과',
  InspectionReject: '검수 반려',
  InspectionSuccessMessage: '검수가 완료되었습니다.',
  InputBreedKeywords: '키워드를 입력하세요',
  InputDeliveryCode: '송장번호를 입력하세요.',
  InputOptionName: '옵션명을 입력하세요. ex)사이즈,색상 등',
  InputOptionPrice: '옵션의 추가금액을 입력하세요.기본값은 0입니다.',
  InputOptionValue: '옵션의 값을 입력하세요.',
  Insert: '등록',
  JuminNumber: '주민등록번호',
  Keyword: '키워드',
  LastName: '성',
  LeavedAt: '탈퇴 일시',
  List: '목록',
  Like: '좋아요',
  Location: '위치',
  MaterialBy: '소재별',
  Manager: '담당자',
  Male: '수컷',
  Mall: '쇼핑몰',
  Match: '일치',
  Mediafile: '미디어파일',
  Message: '내용',
  MileageAdd: '적립된 포인트 금액',
  MileageFree: '적립금 지급 여부',
  MileageUse: '사용한 적립금 금액',
  MoveToHome: '홈화면으로 이동',
  Name: '이름',
  NameChangedMessage: '이름을 성공적으로 변경하였습니다.',
  Neuter: ' 중성화 여부',
  Neuter_false: '중성화 안함',
  Neuter_true: '중성화 함',
  NewPassword: '새 비밀번호',
  NickName: '닉네임',
  No: '아니오',
  NoMediafiles: '없음',
  Notice: '공지',
  NotMatch: '불일치',
  NotQualified: '대상 아님',
  Open: '진행중',
  Order: '주문',
  OrderCode: '주문번호',
  OrderItem: '주문상품',
  OrderPrice: ' 주문금액',
  OrderUser: '주문한 회원 정보',
  OrderedAt: '주문일시',
  Origin: '나라',
  OptionValueHelpMessage: '값을 하나씩 입력하세요. ex)S,M,L,빨강,파랑 등',
  Options: '옵션',
  OptionValue: '옵션값',
  OptionsPrice: '추가금액',
  OptionYes: '옵션있음',
  OptionNo: '옵션없음',
  Owner: '보호자',
  Okay: '확인',
  Partner: '협력업체',
  Pass: '통과',
  Password: '비밀번호',
  PasswordChangedMessage: '비밀번호를 성공적으로 변경하였습니다.',
  PasswordConfirm: '비밀번호 재입력',
  Pay: '결제',
  PayCancel: '주문 취소',
  PayCancelMessage:
    '주문을 취소하시겠습니까?\n주문취소하면 결제도 자동적으로 취소됩니다.',
  PayCancelDoneMessage: '주문을 취소했습니다.',
  PayMethod: '결제 수단',
  PayPrice: '결제금액',
  PayTid: '아임포트 거래 코드',
  PayReceiptUrl: '영수증 URL',
  PetOwner: '보호자',
  Phone: '전화번호',
  PhoneType: '전화번호 유형',
  PinLevel: '상단 노출 순위',
  PinLevelChangeDoneMessage: '노출 순위를 변경했습니다.',
  Post: '게시글',
  Point: '포인트',
  PostClick: '클릭',
  Position: '직책',
  Price: '가격',
  Preview: '미리보기',
  Product: '상품',
  ProductCode: '상품코드',
  ProductStatusChangeDoneMessage: '상품의 판매 상태를 변경하였습니다.',
  ProductMileageChangeDoneMessage: '적립금 지급 상태를 변경하였습니다.',
  ProductName: '상품명',
  ProductNewDoneMessage: '상품 등록을 완료하였습니다.',
  Profile: '프로필',
  Qna: 'Q&A',
  Ready: '대기',
  RealTime: '실시간',
  Reciver: '받는사람 정보',
  Referer: '소속회사',
  Register: '등록',
  RetailPrice: '소비자 가격',
  Reject: '반려',
  RejectMessage: '반려 메세지',
  Remove: '삭제',
  RemoveConfirmMessage: '정말 삭제하시겠습니까?',
  RequiredValue: '필수입니다.',
  ResetPassword: '비밀번호 초기화',
  ResetPasswordDoneMessage: '비밀번호를 새 비밀번호로 설정하였습니다.',
  Reservation: '예약',
  Role: '권한',
  RoleEditDoneMessage: '수정된 권한 정보를 성공적으로 저장하였습니다.',
  RoleNewDoneMessage: '새로운 권한이 등록되었습니다.',
  RoleRemoveConfirmMessage: '선택한 권한을 삭제하시겠습니까?',
  Save: '저장',
  Season: '시즌',
  SeasonBy: '계절별',
  SelectRejectMessage: '반려 사유를 선택하세요.',
  Send: '보내기',
  Sequence: '순서',
  Settings: '설정',
  Share: '공유',
  SignIn: '로그인',
  SignInChannel: '로그인 채널',
  SignOut: '로그아웃',
  SignOutConfirmMessage: '로그아웃하시겠습니까?',
  SignUp: '회원 가입',
  SignUpDone: '회원 가입 완료',
  SignUpDoneMessage:
    '회원가입 요청이 성공적으로 완료되었습니다. 메일을 열고 계정을 활성화하십시오.',
  Species: '종',
  Staff: '직원',
  StaffNewDoneMessage: '신규 계정을 등록하였습니다.',
  Stock: '재고',
  StatDaily: '통계',
  StatDailyData: '데이터 일별 전일 통계',
  StatDailyUsers: '참가자 일별 전일 통계',
  StatTotal: '누적 통계',
  StatTotalData: '데이터 일별 누적 통계',
  StatTotalUsers: '참가자 일별 누적 통계',
  Status: '상태',
  StatusUpdateDoneMessage: '상태가 변경되었습니다.',
  Subtitle: '소제목',
  Submit: '제출',
  SupplyPrice: '공급 가격',
  Supplier: '공급자',
  System: '시스템',
  SystemInfo: '시스템 정보',
  SystemSettings: '시스템 설정',
  Theme: '테마',
  Title: '제목',
  Today: '오늘',
  TotalCount: '총 %{count} 건',
  Type: '유형',
  Update: '수정',
  UpdateDoneMessage: '수정이 완료되었습니다.',
  Upload: '업로드',
  User: '회원',
  UserActivation: '회원 계정 활성화',
  UserDeactivated: '비활성화된 회원',
  UserDeleteConfirmMessage: '선택한 %{count}명의 회원(들)을 삭제하시겠습니까?',
  UserLeaved: '탈퇴 회원',
  Username: '아이디',
  UserVerificationFailedMessage:
    '계정 활성화에 실패하였습니다. 토큰 문자열이 기한이 지났거나 올바르지 않은 문자열입니다. 확인 후 다시 시도하세요.',
  UserVerifiedMessage: '회원가입이 완료되었고 활성화되었습니다.',
  Verification: '인증',
  VerificationDistance: '인증 일치율',
  WelcomeMessage: '환영합니다!',
  Yes: '예',
  Height: '키',
  Weight: '몸무게',
  Waist: '허리둘레',
  WaistHeight: '허리높이',
  Hip: '엉덩이둘레',
  Chest: '가슴둘레',
  Shoulder: '어께사이길이',
  ArmLength: '팔길이',
  BackLength: '등길이',
  AgeRange: '연령대',
  ClothSize: '기성복사이즈',
  UpdatedAt: '수정 일시',
  ReservedDate: '예약일',
  Request: '요청',
  Memo: '메모',
  Breeder: '브리더',
  Introduce: '소개글',
  Photo: '사진',
};

export default messages;
