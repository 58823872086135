import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { IEventAnimal } from 'face-id-types';

import { Button, Modal } from '../../components';
import { formatDateTime, t, getAge, getPetmilyImageURL } from '../../lib';

interface Props {
  open?: boolean;
  eventAnimal: any;
  onClose: (value?: any) => void;
}

const AnimalView = ({ open, eventAnimal, onClose }: Props) => {
  const { animal, mediaFile, inspection } = eventAnimal || {};
  return (
    <Modal
      open={open}
      title={'반려동물 상세'}
      Actions={<Button onClick={onClose}>{t('Okay')}</Button>}
      onClose={onClose}
    >
      {mediaFile ? (
        <List>
          <ListItem secondaryAction={<Typography>{animal?.name}</Typography>}>
            <Typography variant="body1">{t('Name')}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>
                {' '}
                {t(`INSPECTION_STATUS_${inspection?.status}`)}
              </Typography>
            }
          >
            <Typography>{t('Inspection')}</Typography>
          </ListItem>
          <img src={getPetmilyImageURL(mediaFile)} style={{ width: '100%' }} />
        </List>
      ) : (
        <List>
          <ListItem secondaryAction={<Typography>{animal?.name}</Typography>}>
            <Typography variant="body1">{t('Name')}</Typography>
          </ListItem>
          <ListItem secondaryAction={<Typography>{animal?.arn}</Typography>}>
            <Typography variant="body1">{t('Arn')}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>
                {animal?.gnder === 'MALE' ? t('Male') : t('Female')}
              </Typography>
            }
          >
            <Typography variant="body1">{t('Gender')}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>{t(`Neuter_${animal?.neuter}`)}</Typography>
            }
          >
            <Typography variant="body1">{t('Neuter')}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography>{`${getAge(animal?.birthday)}살`}</Typography>
            }
          >
            <Typography variant="body1">{'나이'}</Typography>
          </ListItem>
          <ListItem
            secondaryAction={<Typography>{animal?.breed?.name}</Typography>}
          >
            <Typography variant="body1">{t('Breed')}</Typography>
          </ListItem>
          <ListItem secondaryAction={<Typography>{animal?.color}</Typography>}>
            <Typography variant="body1">{t('Color')}</Typography>
          </ListItem>

          <ListItem
            secondaryAction={
              <Typography>
                {formatDateTime(animal?.createdAt, 'YYYY-MM-DD HH:mm')}
              </Typography>
            }
          >
            <Typography variant="body1">{t('CreatedAt')}</Typography>
          </ListItem>
        </List>
      )}
    </Modal>
  );
};

export default AnimalView;
