import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { LayoutType } from 'face-id-types';

import { LAYOUT } from '../config/styles';
import mainMenu from '../config/menu';
import AccountBar from './AccountBar';

export interface MenuDummy {
  type: 'list' | 'divider';
  list?: MenuItem[];
  roles?: string[];
}

export interface MenuItem {
  title: string;
  path?: string;
  children?: MenuItem[];
  icon?: string;
  roles?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: LAYOUT.menuWidth,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
  roles?: string[];
  open: boolean;
  direction: Anchor;
  layout: LayoutType | undefined;
  onProfile: () => void;
  onSignOut: (value: boolean) => void;
  onToggleMenu: (
    anchor: Anchor,
    open: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const filterRoles = (menu: any, roles: string[]) => {
  const rootRole = -1 < _.findIndex(roles, (role) => role === 'ROLE_ROOT');

  if (rootRole) {
    return menu;
  } else {
    if (menu?.roles) {
      for (let i = 0; i < menu?.roles?.length; i++) {
        const role = menu?.roles[i];
        for (let j = 0; j < roles?.length; j++) {
          if (role === roles[j]) {
            return menu;
          }
        }
      }
    }
  }
};

const MyMenu = ({
  roles,
  open,
  direction,
  layout,
  onProfile,
  onSignOut,
  onToggleMenu,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isBeyondMobile = useMediaQuery('(min-width:600px)');

  const [openFolder, setOpenFolder] = useState<number | null>(null);
  const [selected, setSelected] = useState<number | null>(null);

  const getSelectedIndex = (path: string): number => {
    let selectedIndex = null;
    const menues = mainMenu;
    menues.map((doc: MenuDummy, index: number) => {
      const { list } = doc;

      list &&
        list.map((item: MenuItem) => {
          const { children } = item;
          if (children) {
            children.map((child: MenuItem) => {
              // if (path.indexOf(child.path) > -1) {
              if (path === child.path) {
                selectedIndex = index;
              }
            });
          } else {
            if (path === item.path) {
              // if (path.indexOf(item.path) > -1) {
              selectedIndex = index;
            }
          }
        });
    });
    return selectedIndex;
  };

  const setSelectedFolder = () => {
    let selectedIndex = null;

    const path = location.pathname;
    selectedIndex = getSelectedIndex(path);
    if (selectedIndex) {
      setOpenFolder(selectedIndex);
    }
  };

  useEffect(() => {
    setSelectedFolder();
  }, [history]);

  const toggleFolder = (j: number) => {
    setOpenFolder(openFolder === j ? null : j);
  };

  const handleProfile = () => {
    setSelected(null);
    onProfile();
  };

  const handleSignOut = (value: boolean) => {
    if (value) onSignOut(value);
  };

  const list = (anchor: Anchor) => {
    const handleClick = (item: MenuItem | undefined, index: number) => {
      if (!item?.path) return;
      setSelected(index);
      history.push(item.path);
    };

    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={onToggleMenu(anchor, false)}
        onKeyDown={onToggleMenu(anchor, false)}
      >
        {mainMenu.map((menu, i) => {
          // if (menu.type === 'divider') {
          //   return <Divider key={i} />;
          // }

          return (
            <List key={i} dense disablePadding>
              {filterRoles(menu, roles)?.list?.map(
                (item: MenuItem, j: number) =>
                  item.path ? (
                    <ListItem
                      key={j}
                      dense
                      disableGutters
                      onClick={() => {
                        handleClick(item, i);
                      }}
                    >
                      <ListItemButton
                        selected={location?.pathname === item.path}
                      >
                        <ListItemIcon>
                          <Icon>{item.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <div key={j}>
                      <ListItem
                        dense
                        disableGutters
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFolder(i);
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Icon>
                              {openFolder === i ? 'folder_open' : 'folder'}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                          {openFolder === i ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={openFolder === i}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding>
                          {item.children?.map((doc, k) => (
                            <ListItem
                              key={k}
                              dense
                              disableGutters
                              onClick={() => {
                                handleClick(doc, i);
                              }}
                            >
                              <ListItemButton
                                selected={
                                  location?.pathname?.indexOf(doc.path) > -1
                                }
                              >
                                <ListItemIcon className={classes.nested}>
                                  <Icon>{doc.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={doc.title} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ),
              )}
            </List>
          );
        })}
      </div>
    );
  };

  return (
    <Drawer
      anchor={direction}
      open={open}
      variant={LAYOUT.fixedMenu && isBeyondMobile ? 'permanent' : 'temporary'}
      onClose={onToggleMenu(direction, false)}
    >
      {layout === 'FULL_WIDTH' && isBeyondMobile && <Toolbar />}
      {!isBeyondMobile && (
        <React.Fragment>
          <AccountBar onProfile={handleProfile} onSignOut={handleSignOut} />
          <Divider />
        </React.Fragment>
      )}
      {list(direction)}
    </Drawer>
  );
};

export default MyMenu;
