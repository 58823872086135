interface Props {
  existing?: any;
  incomming?: any;
  options?: any;
}
/**
 * OffsetLimt 방식의 페이지 처리를 구현한다.
 */
export const offsetLimitPagination = (keyArgs?: string[]) => {
  return {
    keyArgs,
    merge(existing?: any, incoming?: any, options?: any): any {
      //   console.log('existing', existing);
      //   console.log('incoming', incoming);
      //   console.log('options', options);
      //   const merged = existing ? existing.slice(0) : undefined; //[];
      //   console.log('merged before', merged);
      //   if (incoming) {
      //     if (options?.args) {
      //       // Assume an offset of 0 if args.offset omitted.
      //       const { skip = 0 } = options.args;
      //       for (let i = 0; i < incoming.length; ++i) {
      //         merged[skip + i] = incoming[i];
      //       }
      //       console.log('merged after', merged);
      //       //     } else {
      //       //       // It's unusual (probably a mistake) for a paginated field not
      //       //       // to receive any arguments, so you might prefer to throw an
      //       //       // exception here, instead of recovering by appending incoming
      //       //       // onto the existing array.
      //       //       merged.push.apply(merged, incoming);
      //     }
      //   }
      //   return merged;
      return undefined;
    },
  };
};
