import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { IEventAnimal } from 'face-id-types';

import { Button, Modal } from '../../components';
import { formatDateTime, t } from '../../lib';

interface Props {
  open?: boolean;
  eventAnimal: IEventAnimal;
  onClose: (value?: any) => void;
}

const AnimalView = ({ open, eventAnimal, onClose }: Props) => {
  return (
    <Modal
      open={open}
      title={eventAnimal?.animal?.name}
      Actions={<Button onClick={onClose}>{t('Okay')}</Button>}
      onClose={onClose}
    >
      <List>
        <ListItem
          secondaryAction={
            <Typography>{eventAnimal?._id.toString()}</Typography>
          }
        >
          <Typography variant="body1">ID</Typography>
        </ListItem>
        <ListItem
          secondaryAction={
            <Typography>{eventAnimal?.animal?.breed?.name}</Typography>
          }
        >
          <Typography variant="body1">{t('Breed')}</Typography>
        </ListItem>
        <ListItem
          secondaryAction={<Typography>{eventAnimal?.animal?.arn}</Typography>}
        >
          <Typography variant="body1">{t('Arn')}</Typography>
        </ListItem>
        <ListItem
          secondaryAction={<Typography>{eventAnimal?.arOrg}</Typography>}
        >
          <Typography variant="body1">{t('ArOrg')}</Typography>
        </ListItem>
        <ListItem
          secondaryAction={
            <Typography>
              {formatDateTime(eventAnimal?.createdAt, 'YYYY-MM-DD HH:mm')}
            </Typography>
          }
        >
          <Typography variant="body1">{t('CreatedAt')}</Typography>
        </ListItem>
      </List>
    </Modal>
  );
};

export default AnimalView;
