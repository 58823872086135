import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { IEventAnimal, IListParams, IPagination, ISortBy } from 'face-id-types';

import { PAGINATION_DEFAULT } from '../../config/constants';
import { filterGraphQLErrors, updateSort } from '../../lib';
import { FACE_ID_EMBED_LIST } from '../../graphql';
import EmbedList from './EmbedList';

const EmbedListContainer = () => {
  const history = useHistory();

  const [listParams, setListParams] = useState<IListParams>({
    skip: PAGINATION_DEFAULT.page * PAGINATION_DEFAULT.rowsPerPage,
    limit: PAGINATION_DEFAULT.rowsPerPage,
    sortBy: [{ field: '_id', order: 'DESC' }],
  });

  const { loading, error, data } = useQuery(FACE_ID_EMBED_LIST, {
    variables: { params: listParams },
  });

  const handleAction = (command: string, params?: unknown) => {
    let updatedSort;
    const pagination = params as IPagination;
    switch (command) {
      case 'INSPECT':
        history.push(
          `/data-event-animals/${(params as IEventAnimal)._id}/inspect`,
        );
        break;

      case 'EDIT':
        history.push(`/data-event-animals/${params}/edit`);
        break;

      case 'DELETE':
        console.log('DELETE', params);
        break;

      case 'PAGE':
        setListParams({
          ...listParams,
          skip: pagination.page * listParams.limit,
        });
        break;

      case 'ROWS_PER_PAGE':
        setListParams({
          ...listParams,
          skip: 0,
          limit: pagination.rowsPerPage,
        });
        break;

      case 'SORT':
        updatedSort = updateSort(listParams.sortBy, params as ISortBy);
        setListParams({
          ...listParams,
          skip: 0,
          sortBy: updatedSort,
        });
        break;

      default:
    }
  };

  return (
    <EmbedList
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminFaceIdEmbedList?.list || []}
      sort={listParams.sortBy}
      pagination={{
        count: data?.adminFaceIdEmbedList?.totalCount || 0,
        page: Math.floor(listParams.skip / listParams.limit),
        rowsPerPage: listParams.limit,
        rowsPerPageOptions: PAGINATION_DEFAULT.rowsPerPageOptions,
      }}
      onAction={handleAction}
    />
  );
};

export default EmbedListContainer;
