import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { IEventUser } from 'face-id-types';

import { Button, Modal } from '../../components';
import { phoneFormat, printJuminNumber, roadAddress, t } from '../../lib';

interface Props {
  open?: boolean;
  eventUser: IEventUser;
  onClose: (value?: any) => void;
}

const UserView = ({ open, eventUser, onClose }: Props) => {
  return (
    <Modal
      open={open}
      title={eventUser?.phoneAuth?.name}
      Actions={<Button onClick={onClose}>{t('Okay')}</Button>}
      onClose={onClose}
    >
      <List>
        <ListItem
          secondaryAction={<Typography>{eventUser?._id.toString()}</Typography>}
        >
          <Typography variant="body1">ID</Typography>
        </ListItem>
        <ListItem
          secondaryAction={<Typography>{eventUser?.user?.username}</Typography>}
        >
          <Typography variant="body1">{t('Username')}</Typography>
        </ListItem>
        {/* <ListItem
          secondaryAction={
            <Typography>
              {eventUser?.juminNumber &&
                printJuminNumber(eventUser.juminNumber)}
            </Typography>
          }
        >
          <Typography variant="body1">{t('JuminNumber')}</Typography>
        </ListItem> */}
        <ListItem
          secondaryAction={
            <Typography>
              {eventUser?.phoneAuth?.phoneNumber &&
                phoneFormat(eventUser.phoneAuth.phoneNumber)}
            </Typography>
          }
        >
          <Typography variant="body1">{t('Phone')}</Typography>
        </ListItem>
        {/* <ListItem
          secondaryAction={
            <Typography>
              {eventUser?.address && roadAddress(eventUser.address)}
            </Typography>
          }
        >
          <Typography variant="body1">{t('Address')}</Typography>
        </ListItem> */}
      </List>
    </Modal>
  );
};

export default UserView;
