import { gql } from '@apollo/client';

const PET_LIST = gql`
  query PetList($searchText: String, $skip: Int, $limit: Int) {
    petList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        type
        name
        breed {
          _id
          name
        }
        birthday
        gender
        arn
        owner {
          _id
          name
        }
        breeder {
          _id
          name
        }
        reservedAt
        adoptedAt
        createdAt
        updatedAt
      }
    }
  }
`;

const PET_VIEW = gql`
  query PetView($id: ID) {
    petView(id: $id) {
      _id
      type
      name
      breed {
        _id
        name
      }
      birthday
      gender
      arn
      owner {
        _id
        name
      }
      breeder {
        _id
        name
      }
      reservedAt
      adoptedAt
      createdAt
      updatedAt
    }
  }
`;

const PET_NEW = gql`
  mutation PetNew($input: PetInput) {
    petNew(input: $input) {
      _id
    }
  }
`;

const PET_UPDATE = gql`
  mutation PetUpdate($id: ID, $input: PetInput) {
    petUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const PET_REMOVE = gql`
  mutation PetRemove($id: ID) {
    petRemove(id: $id)
  }
`;

export { PET_LIST, PET_VIEW, PET_NEW, PET_UPDATE, PET_REMOVE };
