import { gql } from '@apollo/client';

const CONSULTING_LIST = gql`
  query AdminConsultingList($params: ListParams) {
    adminConsultingList(params: $params) {
      _id
      totalCount
      list {
        _id
        name
        phoneNumber
        location
        message
        comment
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const CONSULTING_VIEW = gql`
  query AdminConsultingView($id: ID) {
    adminConsultingView(id: $id) {
      _id
      name
      phoneNumber
      location
      message
      comment
      status
      createdAt
      updatedAt
    }
  }
`;

const CONSULTING_NEW = gql`
  mutation ConsultingNew($input: ConsultingInput) {
    consultingNew(input: $input) {
      _id
      name
      phoneNumber
      location
      message
      comment
      status
      createdAt
      updatedAt
    }
  }
`;

const CONSULTING_UPDATE = gql`
  mutation AdminConsultingUpdate($id: ID, $input: ConsultingInput) {
    adminConsultingUpdate(id: $id, input: $input) {
      _id
      name
      phoneNumber
      location
      message
      comment
      status
      createdAt
      updatedAt
    }
  }
`;

const CONSULTING_REMOVE = gql`
  mutation ConsultingRemove($id: ID) {
    consultingRemove(id: $id)
  }
`;

export {
  CONSULTING_LIST,
  CONSULTING_VIEW,
  CONSULTING_NEW,
  CONSULTING_UPDATE,
  CONSULTING_REMOVE,
};
