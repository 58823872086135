import { ApolloError } from '@apollo/client';
import _ from 'lodash';
import { IError, IInputError } from 'face-id-types';

import { t } from '../lib';

export const filterGraphQLErrors = (
  error: ApolloError | undefined,
): IError[] | undefined => {
  if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
    console.log('filterGraphQLErrors', error.graphQLErrors);
    return error.graphQLErrors.map((doc): IError => {
      const inputErrors: IInputError[] = [];
      if (doc.message === 'error_user_input') {
        const keys = doc.extensions && Object.keys(doc.extensions);
        if (keys && keys.length > 0) {
          keys.forEach((key) => {
            const value: IInputError =
              doc.extensions &&
              doc.extensions[key] &&
              doc.extensions[key].extensions;
            if (value) inputErrors.push(value);
          });
        }
      } else if (doc.extensions?.argumentName) {
        return {
          message: doc.message,
          code: doc.extensions.code,
          inputErrors: [
            _.pick(doc.extensions, ['message', 'code', 'argumentName']),
          ],
        };
      }

      return {
        message: doc.message,
        code: doc.extensions?.code,
        inputErrors,
      };
    });
  }
};

export const findFieldError = (
  errors: IError[] | undefined,
  fieldName: string,
): string | undefined => {
  const fieldErrors: string[] | undefined = [];
  if (!errors || errors.length === 0) return;

  errors.forEach((error) => {
    if (error.inputErrors && error.inputErrors.length > 0) {
      error.inputErrors.forEach((doc) => {
        if (doc.argumentName === fieldName) {
          fieldErrors?.push(t(doc.message));
        }
      });
    }
  });

  return _.join(fieldErrors, '<br />');
};

interface ClassifiedError {
  [key: string]: string[] | undefined;
}

export const classifyErrors = (errors: IError[] | undefined) => {
  if (!errors || errors.length === 0) return;

  const classified: ClassifiedError = {};

  errors.forEach((error) => {
    if (error.inputErrors && error.inputErrors.length > 0) {
      error.inputErrors.forEach((doc) => {
        if (doc.argumentName) {
          const errorField = classified[doc.argumentName];
          if (!errorField) {
            classified[doc.argumentName] = [t(doc.message)];
          } else {
            errorField.push(t(doc.message));
          }
        } else {
          if (!classified._common) {
            classified._common = [doc.message];
          } else {
            classified._common.push(doc.message);
          }
        }
      });
    } else {
      let message;
      if (error.message.startsWith('E11000 duplicate key error')) {
        message = t('error_duplicate_key');
      } else {
        message = error.message;
      }

      if (!classified._common) {
        classified._common = [message];
      } else {
        classified._common.push(message);
      }
    }
  });

  return classified;
};
