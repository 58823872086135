import _ from 'lodash';
import { IUser } from 'face-id-types';

export const getUserName = (user: IUser): string | undefined => {
  const name =
    user.username || `${user.general?.lastName} ${user.general?.firstName}`;

  return !_.isEmpty(name) ? name : user.emails[0].address;
};

export const getUserAvatar = (user: IUser): string | undefined => {
  return user.emails[0].address.substr(0, 1);
};

export const getUserRoles = (user: IUser): string[] | undefined => {
  const rtn: string[] = [];
  user?.roles?.map((item) => rtn.push(item));
  return rtn;
};
