import { createTheme, Theme } from '@mui/material/styles';

export const DefaultTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3f51b5', // main: "#17D8FC",
    },
  },
});

export const DarkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const getTheme = (
  mode: 'light' | 'dark' | 'auto',
  prefersDarkMode: boolean,
): Theme => {
  let theme = mode === 'dark' ? DarkTheme : DefaultTheme;
  if (mode === 'auto') {
    theme = prefersDarkMode ? DarkTheme : DefaultTheme;
  }
  return theme;
};
