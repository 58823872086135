import { gql } from '@apollo/client';

export const ADMIN_SYSTEM_LIST = gql`
  query AdminSystemList {
    adminSystemList {
      _id
      dataPublic
      dataPrivate
      createdAt
      updatedAt
    }
  }
`;

export const ADMIN_SYSTEM_VIEW = gql`
  query AdminSystemView($id: ID) {
    adminSystemView(id: $id) {
      _id
      dataPublic
    }
  }
`;
