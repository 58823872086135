import React from 'react';
import { IError, IEventImage } from 'face-id-types';

import { Modal } from '../../components';
import { getPetmilyImageURL, t } from '../../lib';

interface Props {
  open: boolean;
  loading: boolean;
  errors?: IError[];
  list?: any;
  onClose?: () => void;
}

const ImageList = ({ open, loading, errors, list, onClose }: Props) => {
  return (
    <Modal open={open} title={t('ImageList')} onClose={onClose}>
      {list?.map((item: any) => (
        <img
          key={item._id.toString()}
          src={getPetmilyImageURL(item.mediaFile, 'thumbnail')}
          style={{ height: 80 }}
        />
      ))}
    </Modal>
  );
};

export default ImageList;
