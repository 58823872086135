import React, { useRef, useState } from 'react';
import {
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  IError,
  IEventImage,
  IEventUser,
  IPagination,
  ISortBy,
  IMediaFile,
} from 'face-id-types';

import { Confirm, DataTable, Modal } from '../../components';
import { findSortOrder, getImageURL, t } from '../../lib';
import { Page } from '../../layout';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IEventUser[];
  sort?: ISortBy[];
  status?: string | undefined;
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
  onChangeStatus?: (status: string | undefined) => void;
}
interface PopupProps {
  open: boolean;
  mediaFile?: IMediaFile;
  onClose: () => void;
}

const ImageViewPopup = ({ open, mediaFile, onClose }: PopupProps) => {
  return (
    <Modal title={t('ImageList')} open={open} onClose={onClose}>
      <img src={getImageURL(mediaFile, 'thumbnail')} />
    </Modal>
  );
};

const ImageList = ({
  loading,
  errors,
  list,
  sort,
  status,
  pagination,
  onAction,
  onChangeStatus,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [imageViewOpen, setImageViewOpen] = useState<any>({
    open: false,
  });

  const actionArgs = useRef<any>();

  const headers = [
    {
      id: 'animalName',
      label: t('Name'),
      sort: findSortOrder(sort, 'animal.name'),
      getValue: (obj: IEventImage) => obj.animal.name,
    },
    {
      id: 'image',
      label: t('EventData'),
      sort: findSortOrder(sort, 'mediaFile._id'),
      getValue: (obj: IEventImage) => (
        <div
          onClick={() => {
            setImageViewOpen({ open: true, mediaFiles: obj.mediaFile });
          }}
        >
          <img
            src={getImageURL(obj.mediaFile, 'thumbnail')}
            style={{ height: 80 }}
          />
        </div>
      ),
    },
    {
      id: 'imageSize',
      label: t('ImageSize'),
      getValue: (obj: IEventImage) =>
        `${obj?.mediaFile?.width} x ${obj?.mediaFile?.height}`,
    },
    {
      id: 'inspection',
      label: t('Inspection'),
      getValue: (obj: IEventImage) =>
        t(`INSPECTION_STATUS_${obj?.inspection?.status}`),
    },
    {
      id: 'rejectMessage',
      label: t('RejectMessage'),
      getValue: (obj: IEventImage) => obj?.inspection?.rejectMessage,
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: IEventImage) =>
        dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('EventData')}
      pageTitle={
        <Typography variant="h4">
          {t('EventData')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <Grid>
        <ToggleButtonGroup
          color="primary"
          value={status}
          exclusive
          onChange={(e, value): any => {
            if (value) onChangeStatus(value);
          }}
        >
          {/* <ToggleButton value="ALL">전체</ToggleButton> */}
          <ToggleButton value="READY">대기</ToggleButton>
          <ToggleButton value="REJECT">반려</ToggleButton>
          <ToggleButton value="PASS">통과</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IEventUser) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: actionArgs.current?.params?.length,
        })}
        onClose={handleConfirm}
      />
      <ImageViewPopup
        open={imageViewOpen.open}
        mediaFile={imageViewOpen.mediaFiles}
        onClose={() => setImageViewOpen({ open: false, mediaFiles: undefined })}
      />
    </Page>
  );
};

export default ImageList;
