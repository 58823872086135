import React, { ChangeEvent, useState } from 'react';
import { Button, Typography } from '@mui/material';

import {
  BREEDER_LIST,
  BREEDER_VIEW,
  BREEDER_NEW,
  BREEDER_REMOVE,
  BREEDER_UPDATE,
} from '../../graphql';
// import { Table } from '../../components';
import { t, getDate } from '../../lib';
import { Page } from '../../layout';

const List = () => {
  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'addr', label: 'Address' },
    { id: 'updatedAt', label: 'UpdatedAt', type: 'date' },
  ];

  const form = {
    name: 'Breeder',
    crud: {
      c: BREEDER_NEW,
      r: BREEDER_VIEW,
      u: BREEDER_UPDATE,
      d: BREEDER_REMOVE,
    },
    columns: [
      { id: 'name', label: 'Name' },
      { id: 'phone', label: 'Phone' },
      { id: 'addr', label: 'Address' },
      { id: 'photo', label: 'Photo', type: 'image' },
      { id: 'message', label: 'Introduce' },
    ],
  };

  return (
    <Page
      title={t('Breeder')}
      pageTitle={
        <Typography variant="h4">
          {t('Breeder')} <small>{t('List')}</small>
        </Typography>
      }
    >
      {/* <Table
        headers={headers}
        form={form}
        query={{ breederList: BREEDER_LIST }}
      /> */}
    </Page>
  );
};

export default List;
