import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { IError, IList, IPagination, IRole, ISortBy } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IRole[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
  onRemove: (roleId: string) => void;
}

const RoleList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
  onRemove,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const actionArgs = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const [openRoleNew, setOpenRoleNew] = useState<boolean>(false);
  const [openRoleEdit, setOpenRoleEdit] = useState<IRole | undefined>();
  const [openConfirm, setOpenConfirm] = useState<IRole | undefined>();

  useEffect(() => {
    const activeRoleError = _.findIndex(
      errors,
      (error) => error.message === 'active role remove rejected',
    );
    if (activeRoleError > -1) {
      enqueueSnackbar(t('error_active_role_remove_reject_message'), {
        variant: 'error',
      });
    }
  }, [errors]);

  const headers = [
    {
      id: 'name',
      label: t('Name'),
      sort: findSortOrder(sort, 'name'),
      getValue: (obj: IRole) => obj.name,
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      getValue: (obj: IRole) => dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  const handleConfirmRemove = (answer: boolean) => {
    if (answer && openConfirm?._id) {
      onRemove(openConfirm._id);
    }
    setOpenConfirm(undefined);
  };

  return (
    <Page
      title={t('Role')}
      pageTitle={
        <Typography variant="h4">
          {t('Role')} <small>{t('List')}</small>
        </Typography>
      }
      headerRight={
        <Button
          variant="contained"
          onClick={() => {
            setOpenRoleNew(true);
          }}
        >
          {t('Insert')}
        </Button>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IRole) => ({
          id: item._id,
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={!!openConfirm}
        title={`${t('Role')} ${t('Remove')}`}
        message={t('RoleRemoveConfirmMessage')}
        onClose={handleConfirmRemove}
      />
    </Page>
  );
};

export default RoleList;
