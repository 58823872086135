const status = {
  CARD: 'Pay card',
  CANCEL: 'Order cancel',
  KAKAO_PAY: 'Pay Kakaopay',
  READY: 'Ready',
  PAY_READY: 'Pay ready',
  PAID: 'Paid',
  PAID_CONFIRM: 'Paid confirm',
  ON_DELIVERY: 'Delivery',
  DELIVERY_DONE: 'Delivery done',
  ORDER_DONE: 'Order done',
  CONSULTING_STATUS_REQUEST: 'Consulting request',
  CONSULTING_STATUS_RESPONSE: 'Consulting response(phone call)',
  CONSULTING_STATUS_RESERVED: 'Visit reservation',
  CONSULTING_STATUS_VISITED: 'Visit consulting',
  CONSULTING_STATUS_CONTRACTED: 'Adoption contracted',
  CONSULTING_STATUS_ADOPTED: 'Adoption delivered',
  CONSULTING_STATUS_CANCELED: 'Canceled',
  CONSULTING_STATUS_FINISHED: 'Consulting finished',
  INSPECTION_STATUS_READY: 'Ready',
  INSPECTION_STATUS_PASS: 'Pass',
  INSPECTION_STATUS_REJECT: 'Reject',
  USER_STATUS_ACTIVE: 'Active',
  USER_STATUS_HOLD: 'Hold',
  USER_STATUS_INACTIVATED: 'Inactivated',
  USER_STATUS_INTERIM: 'Signup in progress',
  USER_STATUS_LEAVING: 'Withdrawal request',
  USER_STATUS_LEAVED: 'Withdrawal',
};

export default status;
