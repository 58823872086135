import { gql } from '@apollo/client';

export const PETMILY_USER_LIST = gql`
  query AdminPetmilyUserList($params: ListParams) {
    adminPetmilyUserList(params: $params) {
      _id
      totalCount
      list {
        _id
        nickName
        emails {
          address
          verified
        }
        auths {
          channel
          id
        }
        signInChannel
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const PETMILY_EVENT_USER_LIST = gql`
  query AdminPetmilyEventUserList($params: ListParams) {
    adminPetmilyEventUserList(params: $params) {
      _id
      totalCount
      list {
        _id
        createdAt
        updatedAt
      }
    }
  }
`;

export const PETMILY_EVENT_ANIMAL_LIST = gql`
  query AdminPetmilyEventAnimalList($params: ListParams) {
    adminPetmilyEventAnimalList(params: $params) {
      _id
      totalCount
      list {
        _id
        mediaFile {
          _id
          version
          format
          width
          height
        }
        inspection {
          status
          rejectMessage
        }
        ar {
          owner {
            name
            email
            phone {
              #   type
              number
            }
            juminNumber
            address {
              postalCode
              province
              city
              road
              details
              building {
                dong
                name
              }
            }
          }
        }
        eventUser {
          user {
            nickName
          }
        }
        animal {
          name
          breed {
            name
          }
          gender
          neuter
          arn
          color
          birthday
        }

        count {
          upload
          pass
          reject
        }
        createdAt
      }
    }
  }
`;

export const PETMILY_IMAGE_LIST = gql`
  query AdminPetmilyImageList($id: ID, $status: String, $params: ListParams) {
    adminPetmilyImageList(id: $id, status: $status, params: $params) {
      _id
      totalCount
      list {
        _id
        mediaFile {
          _id
          version
          format
          width
          height
        }
        createdAt
      }
    }
  }
`;
