import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { InspectionStatusType, IListParams } from 'face-id-types';

import { IImageListParams } from '../../types';
import { filterGraphQLErrors } from '../../lib';
import { PETMILY_IMAGE_LIST } from '../../graphql';
import ImageListPopup from './ImageListPopup';

interface Props {
  open: boolean;
  animalId?: string;
  status?: InspectionStatusType;
  onClose?: () => void;
}

const ImageListContainer = ({ open, animalId, status, onClose }: Props) => {
  const history = useHistory();

  const [listParams, setListParams] = useState<IListParams>({
    skip: 0,
    limit: 100,
    sortBy: [{ field: 'createdAt', order: 'DESC' }],
  });

  const { loading, error, data } = useQuery(PETMILY_IMAGE_LIST, {
    variables: { id: animalId, status, params: listParams },
  });

  useEffect(() => {
    setListParams({ ...listParams });
  }, []);

  return (
    <ImageListPopup
      open={open}
      loading={loading}
      errors={filterGraphQLErrors(error)}
      list={data?.adminPetmilyImageList?.list || []}
      onClose={onClose}
    />
  );
};

export default ImageListContainer;
