import { Breakpoint } from '@mui/system';
import { SnackbarOrigin } from 'notistack';

const LAYOUT_PAPER_WIDTH: Breakpoint = 'md';
const LAYOUT_BOX_WIDTH: Breakpoint = 'sm';

export const LAYOUT = {
  paperWidth: LAYOUT_PAPER_WIDTH, // LAYOUT PAPER의 화면 너비
  boxWidth: LAYOUT_BOX_WIDTH, // LAYOUT BOX의 화면 너비
  headerRadius: 10,
  menuWidth: 240,
  fixedMenu: true,
};

export const BUTTON_VARIANT: 'contained' | 'outlined' | 'text' =
  'outlined' as const;

export const NOTI_STACK_DEPTH = 3;
export const NOTI_STACK_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
