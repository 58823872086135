import { gql } from '@apollo/client';

const BREEDER_LIST = gql`
  query BreederList($searchText: String, $skip: Int, $limit: Int) {
    breederList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        name
        phone
        addr
        message
        createdAt
        updatedAt
      }
    }
  }
`;

const BREEDER_VIEW = gql`
  query BreederView($id: ID) {
    breederView(id: $id) {
      _id
      name
      phone
      addr
      message
      createdAt
      updatedAt
    }
  }
`;

const BREEDER_NEW = gql`
  mutation BreederNew($input: BreederInput) {
    breederNew(input: $input) {
      _id
    }
  }
`;

const BREEDER_UPDATE = gql`
  mutation BreederUpdate($id: ID, $input: BreederInput) {
    breederUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const BREEDER_REMOVE = gql`
  mutation BreederRemove($id: ID) {
    breederRemove(id: $id)
  }
`;

export {
  BREEDER_LIST,
  BREEDER_VIEW,
  BREEDER_NEW,
  BREEDER_UPDATE,
  BREEDER_REMOVE,
};
