import { gql } from '@apollo/client';

export const DICTIONARY_LIST = gql`
  query DictionaryList($categories: [String], $input: ListParams) {
    dictionaryList(categories: $categories, input: $input) {
      _id
      category
      key
      value
      seq
      updatedAt
    }
  }
`;
