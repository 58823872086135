/**
 * 관리시스템 접근 계정의 비밀번호 재설정 기능을 구현한다.
 */

import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Alert as AlertMessage, Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IError } from 'face-id-types';

import { Page } from '../../layout';
import { findFieldError, t } from '../../lib';
import { Alert, InputPassword } from '../../components';

interface Props {
  submitting: boolean;
  errors?: IError[];
  requestDone: boolean;
  onSubmit: (password: string) => void;
  onAlertClose: () => void;
  onGoHome: () => void;
}

const ResetPassword = ({
  submitting,
  errors,
  requestDone,
  onSubmit,
  onAlertClose,
  onGoHome,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  useEffect(() => {
    setErrorMessage(errors?.map((doc) => t(doc.message)).join('\n'));
  }, [errors]);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorMessage(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (submitting) return;

    onSubmit(password || '');
  };

  return (
    <Page layout="BOX" title={t('ResetPassword')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {errorMessage && (
            <AlertMessage
              sx={{ marginBottom: 2 }}
              severity="error"
              onClose={onAlertClose}
            >
              {errorMessage}
            </AlertMessage>
          )}
          <InputPassword
            name="password"
            value={password}
            label={t('Password')}
            fullWidth
            error={errorMessage ? true : false}
            helperText={findFieldError(errors, 'password')}
            onChange={handleChangePassword}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={submitting}
            variant="contained"
            fullWidth
            onClick={handleSubmit}
          >
            {t('Submit')}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Button variant="text" fullWidth onClick={onGoHome}>
            {t('MoveToHome')}
          </Button>
        </Grid>
      </Grid>
      <Alert
        open={requestDone}
        title={t('ResetPassword')}
        message={t('ResetPasswordDoneMessage')}
        onClose={onAlertClose}
      />
    </Page>
  );
};

export default ResetPassword;
