import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { filterGraphQLErrors } from '../../lib';
import { STAFF_NEW } from '../../graphql';
import StaffNew from './New';

const StaffNewContainer = () => {
  const history = useHistory();

  const [adminStaffNew, { loading, error }] = useMutation(STAFF_NEW, {
    onCompleted: (response) => {
      console.log('response', response);
      if (!response.adminStaffNew) {
        return;
      }

      history.goBack();
    },
  });
  if (error) {
    console.log('error', error);
  }

  const handleSubmit = (input: any) => {
    adminStaffNew({
      variables: { input },
    });
  };

  console.log('StaffNewContainer', loading, error);

  return (
    <StaffNew
      loading={loading}
      errors={filterGraphQLErrors(error)}
      onSubmit={handleSubmit}
    />
  );
};

export default StaffNewContainer;
