import { gql } from '@apollo/client';

const BREED_LIST = gql`
  query AdminBreedList($params: ListParams) {
    adminBreedList(params: $params) {
      _id
      totalCount
      list {
        _id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

const BREED_VIEW = gql`
  query BreedView($id: ID) {
    breedView(id: $id) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`;

const BREED_NEW = gql`
  mutation BreedNew($input: BreedInput) {
    breedNew(input: $input) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`;

const BREED_UPDATE = gql`
  mutation BreedUpdate($id: ID, $input: BreedInput) {
    breedUpdate(id: $id, input: $input) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`;

const BREED_REMOVE = gql`
  mutation BreedRemove($id: ID) {
    breedRemove(id: $id)
  }
`;

export { BREED_LIST, BREED_VIEW, BREED_NEW, BREED_UPDATE, BREED_REMOVE };
