import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { CHANGE_PASSWORD } from '../../graphql';
import ChangePassword from './ChangePassword';
import { filterGraphQLErrors, t } from '../../lib';

const ChangePasswordContainer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [changePassword, { loading: submitting, error }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: (response) => {
        if (response?.changePassword) {
          enqueueSnackbar(t('PasswordChangedMessage'));
        }
      },
    },
  );
  if (error) {
    console.log('error', error);
  }

  const handleSubmit = (password: string, newPassword: string) => {
    if (!submitting) {
      changePassword({
        variables: {
          password,
          newPassword,
        },
      });
    }
  };

  return (
    <ChangePassword
      submitting={submitting}
      errors={filterGraphQLErrors(error)}
      onSubmit={handleSubmit}
    />
  );
};

export default ChangePasswordContainer;
