import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IError, IEventUser, IPagination, ISortBy } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';
import UserView from './UserView';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: any[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
}

const UserList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
}: Props) => {
  const actionArgs = useRef<any>();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [viewUser, setViewUser] = useState<IEventUser | undefined>(undefined);

  const headers = [
    {
      id: 'username',
      label: t('Username'),
      getValue: (obj: any) => obj.nickName,
    },
    {
      id: 'emails.0.address',
      label: t('EmailAddress'),
      getValue: (obj: any) => obj.emails?.[0].address,
    },
    {
      id: 'signInChannel',
      label: t('SignInChannel'),
      getValue: (obj: any) => obj.signInChannel,
    },
    {
      id: 'status',
      label: t('Status'),
      sort: findSortOrder(sort, 'status'),
      getValue: (obj: any) => t(`USER_STATUS_${obj.status}`),
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      sort: findSortOrder(sort, 'createdAt'),
      getValue: (obj: any) => dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  return (
    <Page
      title={t('User')}
      pageTitle={
        <Typography variant="h4">
          {t('User')} <small>{t('List')}</small>
        </Typography>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IEventUser) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        onAction={handleAction}
      />
      <Confirm
        open={confirmOpen}
        title={t('Remove')}
        message={t('UserDeleteConfirmMessage', {
          count: actionArgs.current?.params?.length,
        })}
        onClose={handleConfirm}
      />
      <UserView
        open={!!viewUser}
        eventUser={viewUser}
        onClose={() => {
          setViewUser(undefined);
        }}
      />
    </Page>
  );
};

export default UserList;
