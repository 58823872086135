import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Button, Link, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IError, IList, IPagination, IQna, ISortBy } from 'face-id-types';

import { Confirm, DataTable } from '../../components';
import { findSortOrder, t } from '../../lib';
import { Page } from '../../layout';

interface Props {
  loading: boolean;
  errors?: IError[];
  list?: IQna[];
  sort?: ISortBy[];
  pagination?: IPagination;
  onAction?: (command: string, params?: any) => void;
}

const QnaList = ({
  loading,
  errors,
  list,
  sort,
  pagination,
  onAction,
}: Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const actionArgs = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const [openRoleNew, setOpenRoleNew] = useState<boolean>(false);
  const [openRoleEdit, setOpenRoleEdit] = useState<IQna | undefined>();
  const [openConfirm, setOpenConfirm] = useState<IQna | undefined>();

  useEffect(() => {
    const activeRoleError = _.findIndex(
      errors,
      (error) => error.message === 'active role remove rejected',
    );
    if (activeRoleError > -1) {
      enqueueSnackbar(t('error_active_role_remove_reject_message'), {
        variant: 'error',
      });
    }
  }, [errors]);

  const headers = [
    {
      id: 'title',
      label: t('Title'),
      sort: findSortOrder(sort, 'title'),
      getValue: (obj: IQna) => {
        const value =
          obj.countQuestion > 0
            ? `${obj.title} (${obj.countQuestion})`
            : obj.title;

        return (
          <Link
            component="button"
            onClick={() => {
              onAction('VIEW', obj._id.toString());
            }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      id: 'username',
      label: t('Username'),
      sort: findSortOrder(sort, 'username'),
      getValue: (obj: IQna) => obj.author?.username,
    },
    {
      id: 'closed',
      label: t('Status'),
      sort: findSortOrder(sort, 'closed'),
      getValue: (obj: IQna) => (obj.closed ? t('Finished') : t('Open')),
    },
    {
      id: 'createdAt',
      label: t('CreatedAt'),
      getValue: (obj: IQna) => dayjs(obj.createdAt).format('YYYY-MM-DD HH:mm'),
    },
    {
      id: 'updatedAt',
      label: t('UpdatedAt'),
      getValue: (obj: IQna) => dayjs(obj.updatedAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleAction = (command: string, params: any) => {
    if (command === 'DELETE') {
      actionArgs.current = { command, params };
      setConfirmOpen(true);
    } else {
      onAction(command, params);
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      onAction(actionArgs.current.command, actionArgs.current.params);
    }
    setConfirmOpen(false);
  };

  const handleConfirmRemove = (answer: boolean) => {
    if (answer && openConfirm?._id) {
      // onRemove(openConfirm._id);
    }
    setOpenConfirm(undefined);
  };

  return (
    <Page
      title={t('Qna')}
      pageTitle={
        <Typography variant="h4">
          {t('Qna')} <small>{t('List')}</small>
        </Typography>
      }
      headerRight={
        <Button
          variant="contained"
          onClick={() => {
            setOpenRoleNew(true);
          }}
        >
          {t('Insert')}
        </Button>
      }
    >
      <DataTable
        loading={loading}
        dataHead={headers}
        dataRows={list?.map((item: IQna) => ({
          id: item._id.toString(),
          value: item,
        }))}
        pagination={pagination}
        noSelect
        onAction={handleAction}
      />
      <Confirm
        open={!!openConfirm}
        title={`${t('Role')} ${t('Remove')}`}
        message={t('RoleRemoveConfirmMessage')}
        onClose={handleConfirmRemove}
      />
    </Page>
  );
};

export default QnaList;
