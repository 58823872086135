import { gql } from '@apollo/client';

export const STAFF_LIST = gql`
  query AdminStaffList($params: ListParams) {
    adminStaffList(params: $params) {
      _id
      totalCount
      list {
        _id
        username
        emails {
          address
          verified
        }
        status
        general {
          firstName
          lastName
          name
          phone
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const STAFF_VIEW = gql`
  query AdminStaffView($id: ID) {
    adminStaffView(id: $id) {
      _id
      username
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
        name
        phone
      }
      roles
      createdAt
      updatedAt
    }
  }
`;

export const STAFF_NEW = gql`
  mutation AdminStaffNew($input: StaffInput) {
    adminStaffNew(input: $input) {
      _id
      username
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
        name
        phone
      }
      roles
      createdAt
      updatedAt
    }
  }
`;

export const STAFF_UPDATE = gql`
  mutation AdminStaffUpdate($id: ID, $input: StaffInput) {
    adminStaffUpdate(id: $id, input: $input) {
      _id
      username
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
        name
        phone
      }
      roles
      createdAt
      updatedAt
    }
  }
`;

export const STAFF_REMOVE = gql`
  mutation AdminStaffRemove($id: ID) {
    adminStaffRemove(id: $id)
  }
`;
