import { IListParams, IPagination, IUser } from 'face-id-types';

import { t } from '../lib/i18n';
import { ISessionContext } from '../context/session';

// 앱 일반
export const APP = {
  name: `PetKey ${t('AppName')}${
    process.env.REACT_APP_IS_TEST_MODE === 'true' ? ' (TEST)' : ''
  }`,
  version: '0.1.0',
};

const HOST = process.env.REACT_APP_HOST;
const PORT = process.env.REACT_APP_PORT;
const SSL = process.env.REACT_APP_SSL;

// 서버 접속 주소
export const SERVER = {
  url: `${SSL === 'true' ? 'https' : 'http'}://${HOST}${
    PORT ? ':' + PORT : ''
  }`,
  path: 'graphql',
};

// 회원 세션 정보의 초기 설정값
export const SESSION_CONTEXT_DEFAULT: ISessionContext = {
  account: undefined,
  isAuthenticated: () => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn: (user: IUser) => {
    // do nothing
  },
  signOut: () => {
    // do nothing
  },
  refresh: () => {
    // do nothing
  },
};

// Local Storage 저장용 키값 정의
export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SESSION: 'SESSION',
  THEME_MODE: 'THEME_MODE',
};

export const LIST_ARGS_DEFAULT: IListParams = {
  skip: 0,
  limit: 10,
};

export const PAGINATION_DEFAULT: IPagination = {
  count: 0,
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 20, 50, 100],
};

export const STORAGE_KEY_AI_DATA_INSPECTION_STATUS =
  'AI_DATA_INSPECTION_STATUS';

export const CLOUDINARY_URL_PREFIX = 'https://res.cloudinary.com/node375';
