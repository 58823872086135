import React from 'react';
import { Typography } from '@mui/material';

import { t } from '../../lib';
import { Page } from '../../layout';

const Home = () => {
  return (
    <Page
      layout="FULL_WIDTH"
      title={`${t('AppName')}`}
      pageTitle={<Typography variant="h4">{`${t('AppName')}`}</Typography>}
    >
      <Typography variant="body1">{t('WelcomeMessage')}</Typography>
    </Page>
  );
};

export default Home;
