import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { filterGraphQLErrors } from '../../lib';
import { USER_VIEW } from '../../graphql';
import UserEdit from './Edit';

interface Props {
  id: string;
}

const UserEditContainer = () => {
  const { id } = useParams<Props>();

  const history = useHistory();

  const { loading, error, data } = useQuery(USER_VIEW, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  const handleUserEdit = () => {
    history.push('/forgot-password');
  };

  const handleSubmit = (email: string) => {
    console.log('email', email);
  };

  return (
    <UserEdit
      loading={loading}
      errors={filterGraphQLErrors(error)}
      data={data?.adminUserView}
      //   onUserEdit={handleUserEdit}
      //   onSubmit={handleSubmit}
    />
  );
};

export default UserEditContainer;
