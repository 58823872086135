import _ from 'lodash';
import { ISortBy, SortOrder, SortOrderType } from 'face-id-types';

export const isOfType = <T>(
  varToBeChecked: unknown,
  propertyToCheckFor: keyof T,
): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

/**
 * 정렬방식 3단계를 순차적으로 변경한다.
 *
 * @param order
 * @returns
 */
export const toggleSortOrder = (order: SortOrderType): SortOrderType => {
  if (order === SortOrder.ASC) {
    return SortOrder.DESC;
  } else if (order === SortOrder.DESC) {
    return SortOrder.UNDEF;
  } else {
    return SortOrder.ASC;
  }
};

/**
 * sortBy 배열에서 기존에 존재하는 field를 제거하고, 새로운 sortBy를 추가한다.
 *
 * @param prev
 * @param sortBy
 * @returns
 */
export const updateSort = (prev: ISortBy[], sortBy: ISortBy): ISortBy[] => {
  if (!prev || prev.length === 0) return [sortBy];
  const values = _.dropWhile(prev, (v) => v.field === sortBy.field);
  return [...values, sortBy];
};

/**
 * sortBy 배열에서 지정된 필드에 대한 정렬 설정값을 읽어 리턴한다.
 * 값이 없으면 SortOrder.UNDEF를 리턴한다.
 *
 * @param orders
 * @param field
 * @returns
 */
export const findSortOrder = (
  orders: ISortBy[],
  field: string,
): SortOrderType => {
  const order = orders.find((v) => v.field === field);
  return order ? order.order : SortOrder.UNDEF;
};
