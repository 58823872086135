import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { filterGraphQLErrors } from '../../lib';
import { FORGOT_PASSWORD } from '../../graphql';
import ForgotPassword from './ForgotPassword';

const ForgotPasswordContainer = () => {
  const history = useHistory();

  const [forgotPassword, { loading, error }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (response) => {
      if (!response?.adminForgotPassword) return;

      console.log('forgotPassword response', response.adminForgotPassword);
    },
  });

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleSubmit = (email: string) => {
    forgotPassword({ variables: { email } });
  };

  return (
    <ForgotPassword
      submitting={loading}
      errors={filterGraphQLErrors(error)}
      onForgotPassword={handleForgotPassword}
      onSubmit={handleSubmit}
    />
  );
};

export default ForgotPasswordContainer;
