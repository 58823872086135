import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  IDictionary,
  IEventImage,
  InspectionStatus,
  InspectionStatusType,
} from 'face-id-types';

import { formatDateTime, getImageURL, t } from '../../lib';

interface Props {
  image: IEventImage | undefined;
  rejectMessages?: IDictionary[];
  onSubmit: (
    id: string,
    status: InspectionStatusType,
    rejectMessage?: string,
  ) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  cell: {
    display: 'block',
    padding: theme.spacing(1),
  },
}));

const NO_SELECT = 'NO_SELECT';
const IMAGE_HEIGHT = 240;

const Inspect = ({ image, rejectMessages, onSubmit, onClose }: Props) => {
  const classes = useStyles();

  const [status, setStatus] = useState<InspectionStatusType>(
    image?.inspection?.status || InspectionStatus.READY,
  );
  const [rejectMessage, setRejectMessage] = useState<string>(
    image?.inspection?.rejectMessage || NO_SELECT,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as InspectionStatusType;

    setStatus(value);
    if (value !== InspectionStatus.REJECT) {
      setRejectMessage(NO_SELECT);
    }
  };

  const handleSubmit = () => {
    if (image?._id) {
      onSubmit(image._id.toString(), status, rejectMessage);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={!!image}
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('Inspection')}</DialogTitle>
      <DialogContent>
        {image && (
          <Box className={classes.container}>
            <Box>
              <img
                src={getImageURL(image?.mediaFile, 'compact')}
                style={{ height: IMAGE_HEIGHT }}
              />
            </Box>
            <Box className={classes.cell}>
              <Typography>{image._id.toString()}</Typography>
              <Typography>{image.animal.name}</Typography>
              <Typography>{image.animal.breed?.name}</Typography>
              <Typography>
                {image.mediaFile.width} x {image.mediaFile.height}
              </Typography>
              <Typography>{formatDateTime(image.createdAt)}</Typography>
            </Box>
            <Box className={classes.cell}>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="READY"
                    control={<Radio onChange={handleChange} />}
                    label={t('INSPECTION_STATUS_READY')}
                    checked={status === InspectionStatus.READY}
                  />
                  <FormControlLabel
                    value="PASS"
                    control={<Radio onChange={handleChange} />}
                    label={t('INSPECTION_STATUS_PASS')}
                    checked={status === InspectionStatus.PASS}
                  />
                  <FormControlLabel
                    value="REJECT"
                    control={<Radio onChange={handleChange} />}
                    label={t('INSPECTION_STATUS_REJECT')}
                    checked={status === InspectionStatus.REJECT}
                  />
                </RadioGroup>
              </FormControl>
              {status === InspectionStatus.REJECT && (
                <Box>
                  <FormControl>
                    <Select
                      label=""
                      value={rejectMessage}
                      variant="filled"
                      fullWidth
                      onChange={(e) =>
                        setRejectMessage(e.target.value as InspectionStatusType)
                      }
                    >
                      <MenuItem value={NO_SELECT}>
                        {t('SelectRejectMessage')}
                      </MenuItem>
                      {rejectMessages?.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Typography>{t('CheckDuplicates')}</Typography>
        {image?.duplicates?.length > 0 && (
          <Box className={classes.container}>
            {image.duplicates.map((doc) => (
              <Box key={doc._id.toString()}>
                <img
                  src={getImageURL(doc?.mediaFile, 'compact')}
                  style={{ height: IMAGE_HEIGHT }}
                />
                <Box className={classes.cell}>
                  <Typography>{doc._id.toString()}</Typography>
                  <Typography>{doc.animal.name}</Typography>
                  <Typography>{doc.animal.breed?.name}</Typography>
                  <Typography>
                    {t(`INSPECTION_STATUS_${doc.inspection.status}`)}
                  </Typography>
                  <Typography>{formatDateTime(doc.createdAt)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t('No')}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            !status ||
            (status === InspectionStatus.REJECT && rejectMessage === NO_SELECT)
          }
        >
          {t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Inspect;
