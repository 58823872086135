import React, { useState } from 'react';
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import _ from 'lodash';
import { IPagination, SortOrder, SortOrderType } from 'face-id-types';

import { t, toggleSortOrder } from '../lib';
import Loading from './Loading';

export interface DataHeadCell {
  id: string;
  label: string;
  sort?: SortOrderType;
  getValue?: (obj: any) => any;
}

export interface DataRowCell {
  id: string;
  value: any;
}

export interface DataAction {
  command: string;
  Icon: React.ReactElement;
}

interface Props {
  loading?: boolean;
  dataHead?: DataHeadCell[];
  dataRows?: DataRowCell[];
  pagination?: IPagination;
  noSelect?: boolean;
  actions?: DataAction[];
  onAction?: (command: string, params?: any) => void;
}

export const DataTable = ({
  dataHead,
  dataRows,
  pagination,
  actions,
  loading = false,
  noSelect = false,
  onAction,
}: Props) => {
  const { count, page, rowsPerPage, rowsPerPageOptions } = pagination || {};

  const [selected, setSelected] = useState<string[]>([]);

  const handleToggleSelectAll = () => {
    if (dataRows?.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(dataRows?.map((row) => row.id));
    }
  };

  const handleToggleSelect = (row: DataRowCell) => {
    const newSelected = _.xor(selected, [row.id]);
    setSelected(newSelected);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    onAction('PAGE', { page });
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAction('ROWS_PER_PAGE', { rowsPerPage: parseInt(e.target.value, 10) });
  };

  return (
    <TableContainer>
      <Toolbar disableGutters>
        <Typography sx={{ flexGrow: 1 }}>
          {selected.length > 0
            ? t('CountTotalAndSelected', {
                total: count,
                selected: selected.length,
              })
            : t('CountTotal', { total: count })}
        </Typography>
        {actions?.map((action) => (
          <IconButton
            key={action?.command}
            onClick={() => {
              onAction(action.command);
            }}
          >
            {action.Icon}
          </IconButton>
        ))}
        <IconButton
          disabled={selected.length !== 1}
          onClick={() => {
            onAction('EDIT', selected[0]);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          disabled={selected.length === 0}
          onClick={() => {
            onAction('DELETE', selected);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Toolbar>
      <Table>
        {dataHead?.length > 0 && (
          <TableHead>
            <TableRow>
              {!noSelect && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && dataRows?.length > selected.length
                    }
                    checked={
                      dataRows?.length > 0 &&
                      dataRows.length === selected.length
                    }
                    onChange={handleToggleSelectAll}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              )}
              {_.map(dataHead, ({ id, label, sort }: DataHeadCell) => (
                <TableCell key={id}>
                  {!sort ? (
                    label
                  ) : (
                    <TableSortLabel
                      active={sort === SortOrder.ASC || sort === SortOrder.DESC}
                      direction={sort === SortOrder.DESC ? 'desc' : 'asc'}
                      onClick={() =>
                        onAction('SORT', {
                          field: id,
                          order: toggleSortOrder(sort),
                        })
                      }
                    >
                      {label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={dataHead.length + 1}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : (
            _.map(dataRows, (row: DataRowCell) => (
              <TableRow key={row?.id}>
                {!noSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={_.includes(selected, row.id)}
                      onChange={() => {
                        handleToggleSelect(row);
                      }}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                )}
                {_.map(dataHead, ({ id, getValue }: DataHeadCell) => (
                  <TableCell key={id}>
                    {getValue && getValue(row.value)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {!loading && (
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </TableContainer>
  );
};
